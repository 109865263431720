import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useEffectOnce } from 'react-use';
import { ColumnBox } from '@retrain-ai/ui-design';
import { CircularProgress } from '@mui/material';
import { navigateToFallbackUrl, getSignInfo } from '../login/login.api';
import { LayoutSwitcher } from '../../components/layout-switcher/layout-switcher';
import { IRoleSelectorProps } from './types';
import { VehadartaLayout } from './layout/VehadartaLayout';
import { SignInLayout } from './layout/SignInLayout';

export type RoleType = 'Candidate' | 'Recruiter';

export const RoleSelector: React.FC<IRoleSelectorProps> = (props) => {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffectOnce(() => {
    const fetchSignInInfo = async () => {
      const signInInfo = await getSignInfo();
      if (!signInInfo) {
        navigateToFallbackUrl();
        return;
      }
      const { signInOptions } = signInInfo;

      const otpAvailable = signInOptions.some((option) => option.method === 'OTP');
      if (!otpAvailable) {
        history.push('/sign-in');
        return;
      }

      setIsLoading(false);
    };

    fetchSignInInfo().catch((e) => console.log('error occurred', e));
  });

  if (isLoading) {
    return (
      <ColumnBox alignItems="center" justifyContent="center" height={'100%'}>
        <CircularProgress size="6rem" color="secondary" />
      </ColumnBox>
    );
  }

  return (
    <LayoutSwitcher setting="signInLayout">
      <LayoutSwitcher.Case type="default">
        <SignInLayout {...props} />
      </LayoutSwitcher.Case>
      <LayoutSwitcher.Case type="skeelz">
        <VehadartaLayout {...props} />
      </LayoutSwitcher.Case>
    </LayoutSwitcher>
  );
};
