import { useEffect, useState } from 'react';

export const Timer: React.FC<{ seconds: number; interval?: number }> = ({ seconds, interval = 1000 }) => {
  const [timeLeft, setTimeLeft] = useState(seconds);

  useEffect(() => {
    if (!timeLeft) return;

    const intervalId = setInterval(() => {
      setTimeLeft(timeLeft - 1);
    }, interval);

    return () => clearInterval(intervalId);
  }, [interval, timeLeft]);

  return <span>{timeLeft}</span>;
};
