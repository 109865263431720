import { ColumnBox } from '@retrain-ai/ui-design';
import { useStyles } from './otp.style';
import { FloatingCard } from '../../components/floating-card/floating-card';
import { OtpForm } from './OtpForm';
import { FooterText } from '../../components/footer-text/footer-text';
import { memo } from 'react';

type Props = { onAuth: () => void };

export const Otp: React.FC<Props> = memo(({ onAuth }) => {
  const { classes: s } = useStyles();

  return (
    <FloatingCard bottomLinkText={<FooterText />} bottomLinkHref={'https://www.retrain.ai'}>
      <ColumnBox className={s.container}>
        <OtpForm onAuth={onAuth} />
      </ColumnBox>
    </FloatingCard>
  );
});
