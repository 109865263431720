import React, { useCallback, useRef, useState } from 'react';
import { Security } from '@okta/okta-react';
import { Login } from '../login/login';
import { CircularProgress } from '@mui/material';
import { ColumnBox } from '@retrain-ai/ui-design';
import { getSignInfo } from '../login/login.api';
import { useEffectOnce } from 'react-use';
import { SignInInfoDto, SignInOptionDto } from '@retrain-ai/sign-in-manager-api';
import { OktaAuth } from '@okta/okta-auth-js';
import { RoleSelector } from '../RoleSelector';
import { RoleType } from '../RoleSelector/types';
import { Otp } from '../Otp';
import { Compliance } from '../compliance/compliance';
import i18n from '../../i18n';
import { checkMobileDevice } from '../../utils/check-mobile-device';
import { MobileLogin } from '../MobileLogin/MobileLogin';

const defaultOktaAuth: OktaAuth = new OktaAuth({
  issuer: process.env.REACT_APP_DEFAULT_ISSUER || 'https://retrain.okta.com/oauth2/default',
  redirectUri: process.env.REACT_APP_DEFAULT_REDIRECT_URL || window.location.origin + '/api/sign-in/v1/auth/callback', //window.location.origin + '/sign-in/callback',
  pkce: false,
});

export const SignIn = (props: { navigateToFallbackUrl: () => void }) => {
  const { navigateToFallbackUrl } = props;
  const [buttonSignInInfoOptions, setButtonSignInInfoOptions] = useState<SignInOptionDto[]>([]);
  const [isUserAndPassAvailable, setUserAndPassAvailable] = useState<boolean>(false);
  const [isAutoSignIn, setIsAutoSignInfo] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOtpAvailable, setOtpAvailable] = useState<boolean>(false);
  const [role, setRole] = useState<RoleType | ''>('');
  const [authed, setAuthed] = useState<boolean>(false);
  const [tenantUrl, setTenantUrl] = useState<string>('');
  const [oktaAuth, setOktaAuth] = useState<OktaAuth>(defaultOktaAuth);
  const complianceShown = useRef(false);
  const [showMobileLogin, setShowMobileLogin] = useState<boolean>(checkMobileDevice(navigator.userAgent));

  const onCloseMobileWarning = () => setShowMobileLogin(false);

  useEffectOnce(() => {
    const fetchSignInInfo = async () => {
      const signInInfoDto: (SignInInfoDto & { autoSignIn?: boolean }) | undefined = await getSignInfo();

      if (!signInInfoDto) {
        navigateToFallbackUrl();
        return;
      }
      const { signInOptions } = signInInfoDto;

      setUserAndPassAvailable(signInOptions.some((option) => option.method === 'UserAndPassword'));
      setButtonSignInInfoOptions(signInOptions.filter((option) => option.method !== 'UserAndPassword'));
      setOtpAvailable(signInOptions.some((option) => option.method === 'OTP'));
      setIsAutoSignInfo((signInInfoDto.autoSignIn && signInInfoDto.signInOptions?.length === 1) || false);

      const oktaAuth = new OktaAuth({
        issuer: signInInfoDto.issuer || 'https://retrain.okta.com/oauth2/default',
        clientId: signInInfoDto.clientId || '0oa43fkcdbr1QoGks5d7',
        redirectUri:
          process.env.REACT_APP_DEFAULT_REDIRECT_URL || window.location.origin + '/api/sign-in/v1/auth/callback', //window.location.origin + '/sign-in/callback',
        pkce: false,
      });

      setOktaAuth(oktaAuth);
      setTenantUrl(signInInfoDto.workspaceUrl);
      complianceShown.current = !!window.localStorage.getItem('__rtr_legal');
      if (window.vm.language === '') {
        await i18n.changeLanguage(signInInfoDto.language);
      }
      window.vm.language = signInInfoDto.language as 'en' | 'he';
      setIsLoading(false);
    };

    fetchSignInInfo().catch((e) => console.log('error occurred', e));
  });

  const onComplianceConfirm = useCallback(() => {
    window.localStorage.setItem('__rtr_legal', 'true');
    window.location.href = window.location.origin;
  }, []);

  const onAuthCompleted = useCallback(() => {
    setAuthed(true);
    if (complianceShown.current) {
      window.location.href = window.location.origin;
    }
  }, []);

  if (isLoading) {
    return (
      <ColumnBox alignItems="center" justifyContent="center" height={'100%'}>
        <CircularProgress size="6rem" color="secondary" />
      </ColumnBox>
    );
  }
  if (showMobileLogin) {
    return <MobileLogin onClose={onCloseMobileWarning} />;
  }

  if ((isOtpAvailable || isUserAndPassAvailable) && role === 'Candidate' && authed && !complianceShown.current) {
    return <Compliance onConfirm={onComplianceConfirm} tenantUrl={tenantUrl} />;
  }

  if (isOtpAvailable && !role) {
    return <RoleSelector onSelect={setRole} />;
  }

  if (role === 'Candidate' && isOtpAvailable) {
    return <Otp onAuth={onAuthCompleted} />;
  }

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={(oktaAuth: OktaAuth, originalUrl: string) => {}}>
      <Login
        isUserAndPassAvailable={isUserAndPassAvailable}
        buttonSignInInfoOptions={buttonSignInInfoOptions}
        autoSignIn={isAutoSignIn}
      />
    </Security>
  );
};
