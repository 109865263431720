import { ColumnBox, FBox, Text, TextVariants, SimpleConfirmDialog, Button } from '@retrain-ai/ui-design';
import { useStyles } from './choose-tenant.style';
import { ReactComponent as RetrainLogo } from '../../images/retrain-logo.svg';
import { useTranslation } from 'react-i18next';
import OutlinedInput from '@mui/material/OutlinedInput';
import { FormHelperText, Link } from '@mui/material';
import { FloatingCard } from '../../components/floating-card/floating-card';
import { useEffect, useState } from 'react';
import { useTenantManager } from './tenant-manager';
import Cookies from 'js-cookie';
import { useKey } from 'react-use';

export const ChooseTenant: React.FC = () => {
  const { classes: s, cx } = useStyles();
  const { t } = useTranslation();
  const [tenant, setTenant] = useState('');
  const [isForgotWorkspace, setIsForgotWorkspace] = useState<boolean>(false);
  const [isTenantValid, setIsTenantValid] = useState(true);
  const tenantManager = useTenantManager();

  const isDisabled: boolean = tenant.length === 0;
  const disabledClass = isDisabled ? s.defaultDisabled : null;

  useEffect(() => {
    const rawCookie = Cookies.get('tenant');
    const tenantDto = rawCookie ? JSON.parse(rawCookie) : null;

    if (tenantDto?.url) {
      window.location.href = tenantDto.url;
    }
  }, []);

  const chooseWorkspace = async () => {
    const tenantDto = await tenantManager.validateWorkspace(tenant);

    if (tenantDto) {
      setIsTenantValid(true);
      window.location.href = tenantDto.url;
    } else {
      setIsTenantValid(false);
    }
  };

  useKey('Enter', () => (tenant.length === 0 ? undefined : chooseWorkspace()), { event: 'keypress' }, [tenant]);

  return (
    <FloatingCard bottomLinkText="Powered By Retrain.ai" bottomLinkHref={'https://www.retrain.ai'}>
      <ColumnBox className={s.container}>
        <RetrainLogo />
        <Text variant={TextVariants.RegularTitle} className={s.titleText}>
          {t('choose-tenant.title')}
        </Text>
        <Text className={s.text}>{t('choose-tenant.text')}</Text>
        <Text className={s.inputText}>{t('choose-tenant.workspace-url.text')}</Text>
        <div dir={'auto'}>
          <FBox alignItems={'end'} justifyContent={'flex-start'} className={s.inputContainer}>
            <OutlinedInput
              classes={{
                focused: s.focused,
              }}
              placeholder={t('choose-tenant.workspace-url.text-placeholder')}
              size="medium"
              color="secondary"
              className={s.input}
              onChange={(event) => setTenant(event.target.value)}
              error={!isTenantValid}
            />
            <FBox className={s.endAdornment}>
              <ColumnBox justifyContent="center">
                <Text>.retrain.ai</Text>
              </ColumnBox>
            </FBox>
          </FBox>
        </div>
        {!isTenantValid && <FormHelperText error={!isTenantValid}>{'invalid workspace'}</FormHelperText>}

        <Button
          className={cx(s.button, s.buttonBig, s.buttonDefault, disabledClass)}
          disabled={isDisabled}
          onClick={() => chooseWorkspace()}
          size="large"
        >
          Continue
        </Button>

        <FBox alignItems="center">
          <Link href="#" className={s.link} onClick={() => setIsForgotWorkspace(true)}>
            {t('choose-tenant.forgot-workspace')}
          </Link>
        </FBox>
      </ColumnBox>
      <SimpleConfirmDialog
        isOpen={isForgotWorkspace}
        title={t('choose-tenant.forgot-workspace-title')}
        body={t('choose-tenant.forgot-workspace-text')}
        onClose={() => setIsForgotWorkspace(false)}
      />
    </FloatingCard>
  );
};
