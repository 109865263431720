import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    titleText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(20),
    },

    container: {
      margin: '0 auto',
      padding: theme.spacing(18, 6),
    },

    button: {
      textTransform: 'none',
      width: '100%',
      padding: theme.spacing(3.5, 0),
    },
  }),
);
