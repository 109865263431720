import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    margin: 'auto',
    maxWidth: 400,
    width: '100%',

    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10),
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },
  titleText: {
    fontSize: 30,
    fontWeight: 700,

    marginTop: theme.spacing(4),
  },
  text: {
    marginTop: theme.spacing(2),
  },
  endAdornment: {
    background: '#F0F3FB',
    width: '142px',
    height: '56px',
    borderTopRightRadius: 4,
    borderBottomRightRadius: 4,
    position: 'absolute',
    right: 0,
  },
  input: {
    maxWidth: 260,
    '&.MuiInputBase-root': {
      borderTopRightRadius: 0,
      borderBottomRightRadius: 0,
      width: '100%',
      outline: 'none',
    },
  },
  focused: {
    '& .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px !important',
    },
  },
  inputContainer: {
    position: 'relative',
  },
  inputText: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    color: '#666666',
  },
  button: {
    marginTop: theme.spacing(4),
    textTransform: 'none',
  },
  buttonDefault: {
    color: '#FFFFFF',
    backgroundColor: '#9918B9',
    '&:hover': {
      backgroundColor: '#740290',
      color: '#FFFFFF',
    },
  },
  buttonBig: {
    //TODO: remove when fix button import problem
    padding: theme.spacing(3.5),
    fontSize: '17px',
    lineHeight: '22px',
    borderRadius: 10,
  },
  defaultDisabled: { backgroundColor: '#DFE1E3' },
  link: {
    paddingTop: theme.spacing(2),
    color: '#740290',

    //TODO: remove when have variants:
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
  },
}));
