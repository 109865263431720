import { FC, memo, useCallback, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnBox, TextVariants, Text } from '@retrain-ai/ui-design';
import { SendCodeDto, SendCodeDtoChannelEnum, SendCodeDtoLocaleEnum, VerifyCodeDto } from '@retrain-ai/otp-service-api';
import { channels, initialDestination, validationSchema } from './constants';
import { VerificationScreen } from './VerificationScreen';
import { useStyles, useCommonStyles } from './Otp.style';
import { Divider } from '../../../components/divider/divider';
import { otpApi } from '../otp.api';
import { Form, Formik } from 'formik';
import { RegistrationFields } from './RegistrationFields';
import { DestinationValuesProps, OtpRef, Steps } from './types';
import { authApi } from '../../login/login.api';
import { Timer } from '../../../components/Timer/Timer';
import { useReadOtp } from '../../../utils/web-otp-reader';
import { InAppNotification } from '@retrain-ai/ui-design';

const SEND_CODE_TIMEOUT_MS = 30000;
const SEND_CODE_TIMEOUT_SEC = SEND_CODE_TIMEOUT_MS / 1000;

type Props = { onAuth: () => void };
const getDestination = (values: DestinationValuesProps) => {
  return values.isPhone ? values.phone : values.email;
};

const getOtpChannel = (values: DestinationValuesProps) => {
  const { isPhone } = values || {};
  return {
    destination: getDestination(values),
    channel: isPhone ? channels.sms : channels.email,
  };
};

export const OtpForm: FC<Props> = memo(({ onAuth }) => {
  const { classes: s, cx } = useStyles();
  const { classes: c } = useCommonStyles();
  const { t, i18n } = useTranslation();

  const [destinationInitial, setDestinationInitial] = useState(initialDestination);
  const [otp, setOtp] = useState('');
  const [otpError, setOtpError] = useState('');
  const [destinationError, setDestinationError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isVerifying, setIsVerifying] = useState(false);
  const [isOtpEnabled, setIsOtpEnabled] = useState(false);
  const [invaildUserError, setInvaildUserError] = useState(false);
  const [currentStep, setCurrentStep] = useState(Steps.destination);
  const otpRef = useRef<OtpRef>(null);
  const [sentCodeTo, setSentCodeTo] = useState<string | null>(null);

  useReadOtp(setOtp, {
    isEnabled: isOtpEnabled,
  });

  const onOtpChange = useCallback((value: string) => {
    setOtpError('');
    setInvaildUserError(false);
    setOtp(value);
  }, []);

  const sendVerificationCodeAgain = useCallback(async () => {
    const { destination, channel } = getOtpChannel(destinationInitial) || {};

    if (!destination) return;

    const sendCodeDto = {
      to: destination,
      locale: i18n.language as SendCodeDtoLocaleEnum,
      channel: channel as SendCodeDtoChannelEnum,
    };

    try {
      setIsSubmitting(true);
      await otpApi.otpControllerSendCode({ sendCodeDto });
      setOtpError('');
      setInvaildUserError(false);
      setIsOtpEnabled(true);
    } catch (e) {
      setDestinationError(t('errors.something-wrong'));
    } finally {
      setTimeout(() => {
        setIsSubmitting(false);
      }, SEND_CODE_TIMEOUT_MS);
    }
  }, [destinationInitial, t, i18n.language]);

  const onDestinationSubmit = useCallback(
    async (data: DestinationValuesProps) => {
      const { destination, channel } = getOtpChannel(data) || {};

      const sendCodeDto: SendCodeDto = {
        to: destination,
        locale: i18n.language as SendCodeDtoLocaleEnum,
        channel: channel as SendCodeDtoChannelEnum,
      };

      setDestinationInitial(data);

      try {
        setIsSubmitting(true);
        await otpApi.otpControllerSendCode({ sendCodeDto });
        setSentCodeTo(sendCodeDto.to);
        setOtpError('');
        setInvaildUserError(false);
        setIsOtpEnabled(true);
        setCurrentStep(Steps.verification);
        otpRef.current?.focus();
      } catch (e) {
        setDestinationError(t('errors.something-wrong'));
      } finally {
        setTimeout(() => {
          setIsSubmitting(false);
        }, SEND_CODE_TIMEOUT_MS);
      }
    },
    [t, i18n.language],
  );

  const verifyCode = useCallback(async () => {
    setIsOtpEnabled(false);
    setIsVerifying(true);
    const verifyCodeDto: VerifyCodeDto = {
      to: getDestination(destinationInitial),
      code: otp,
      scope: ['auth.local'],
    };

    let otpToken;
    try {
      ({ data: otpToken } = await otpApi.otpControllerVerifyCode({ verifyCodeDto }));
    } catch (e) {
      setOtpError(t('errors.wrong-code'));
      setIsOtpEnabled(true);
      setIsVerifying(false);
      return;
    }

    try {
      await authApi.authControllerAuthLocal({ headers: { otp_token: otpToken } });
      onAuth();
    } catch (e: any) {
      setInvaildUserError(true);
    } finally {
      setIsOtpEnabled(true);
      setIsVerifying(false);
    }
  }, [destinationInitial, onAuth, otp, t]);

  return (
    <ColumnBox height="100%" className={s.sofiClientRoot}>
      <Text variant={TextVariants.SmallSubtitleBold}>{t('otp.login-quickly-and-safely')}</Text>
      <Text variant={TextVariants.SmallSubtitleBold} className={c.title}>
        {t('otp.select-send-verification-code')}
      </Text>

      <div className={s.registration}>
        <Formik
          validateOnChange={true}
          validateOnBlur={false}
          initialValues={destinationInitial}
          isSubmitting={isSubmitting}
          onSubmit={onDestinationSubmit}
          validationSchema={validationSchema(t)}
        >
          <Form>
            <RegistrationFields
              setError={setDestinationError}
              isSubmitting={isSubmitting}
              disabled={currentStep !== Steps.destination}
            />
          </Form>
        </Formik>
        {destinationError && (
          <Text variant={TextVariants.HintTooltip} className={cx(c.otpError, { [c.visible]: !!destinationError })}>
            {destinationError}
          </Text>
        )}
        {isSubmitting ? (
          <Text variant={TextVariants.ExtraSmallSubtitle} className={s.sendAgain}>
            {t('otp.send-again-timeout')} <Timer seconds={SEND_CODE_TIMEOUT_SEC} />
          </Text>
        ) : (
          <Text variant={TextVariants.ExtraSmallSubtitle} className={s.sendAgain}>
            {t('otp.didnt-received-code')}{' '}
            <span className={c.link} onClick={sendVerificationCodeAgain}>
              {' '}
              {t('otp.send-again')}
            </span>
          </Text>
        )}
      </div>

      <Divider containerClass={c.dividerContainer}>
        <Text variant={TextVariants.SmallRunningText}>{t('otp.type-in-code-below')}</Text>
      </Divider>

      <VerificationScreen
        invalidUserError={invaildUserError}
        verifyCode={verifyCode}
        onOtpChange={onOtpChange}
        otpError={otpError}
        otp={otp}
        isDisabled={currentStep === Steps.destination || isVerifying}
        otpRef={otpRef}
      />
      <InAppNotification
        isOpen={!!sentCodeTo}
        onClose={() => setSentCodeTo(null)}
        title={t('toast.code-sent-to', { mail: sentCodeTo })}
        type="success"
        clickaway
      />
    </ColumnBox>
  );
});
