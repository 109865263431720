import { ColumnBox, TextVariants, Text, Button } from '@retrain-ai/ui-design';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { RoleType } from '../..';
import { FloatingCard } from '../../../../components/floating-card/floating-card';
import { FooterText } from '../../../../components/footer-text/footer-text';
import { useStyles } from './SignIn.styles';
import { Divider } from '../../../../components/divider/divider';

type SignInLayoutType = {
  onSelect: (role: RoleType) => void;
};

export const SignInLayout: FC<SignInLayoutType> = ({ onSelect }) => {
  const { classes: s } = useStyles();
  const { t } = useTranslation();

  return (
    <FloatingCard bottomLinkText={<FooterText />} bottomLinkHref={'https://www.retrain.ai'}>
      <ColumnBox className={s.container}>
        <Text variant={TextVariants.RunningText}>{t('common.welcome-to-retrain')}</Text>

        <Text variant={TextVariants.PageTitle} className={s.titleText}>
          <div>{t('otp.select-how-you')}</div>
          <div>{t('otp.would-like-to-signin')}</div>
        </Text>

        <Button onClick={() => onSelect('Candidate')} className={s.button} size="large">
          <Text variant={TextVariants.SubtitleSemiBold}>{t('otp.candidate')}</Text>
        </Button>

        <Divider>{t('otp.role-divider')}</Divider>

        <Button onClick={() => onSelect('Recruiter')} className={s.button} size="large">
          <Text variant={TextVariants.SubtitleSemiBold}>{t('otp.recruiter')}</Text>
        </Button>
      </ColumnBox>
    </FloatingCard>
  );
};
