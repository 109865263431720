import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    titleText: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(20),
    },

    container: {
      margin: '0 auto',
      padding: theme.spacing(6),
    },

    footerContainer: {
      '& p': {
        color: theme.extraOptions.newPallette.MediumGrey,
      },
    },

    footerLogo: {
      marginLeft: theme.spacing(1.75),
      marginRight: theme.spacing(1),
    },

    termsCheckboxWrapper: {
      marginBottom: theme.spacing(3),
    },

    explanationTitle: {
      [theme.breakpoints.up('sm')]: {
        marginBottom: theme.spacing(3),
      },
    },

    explanationParagraph: {
      [theme.breakpoints.down('xs')]: {
        '&:not(:last-child)': {
          marginBottom: theme.spacing(6),
        },
      },
    },

    wrapper: {
      [theme.breakpoints.down('xs')]: {
        position: 'relative',
        top: theme.spacing(-18.75),
        marginBottom: theme.spacing(-18.75),
      },
    },

    termsDialogHeader: {
      padding: theme.spacing(5),
      boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.08)',
      zIndex: 1,
    },

    termsDialogContent: {
      background: theme.extraOptions.newPallette.White,
      maxWidth: 700,
      padding: theme.spacing(5),
    },

    termsDialogParagraph: {
      paddingBottom: theme.spacing(4),
    },

    termsOfUseSection: {
      background: theme.extraOptions.newPallette.White,
      position: 'sticky',
      width: '100%',
      bottom: 0,

      [theme.breakpoints.down('xs')]: {
        padding: theme.spacing(5.5, 4),
      },

      [theme.breakpoints.up('sm')]: {
        padding: theme.spacing(5.5, 28),
      },
    },

    termsOfUseLink: {
      cursor: 'pointer',
      textDecoration: 'underline',
      color: theme.palette.primary.main,
    },

    footerButton: {
      textTransform: 'none',
      width: '100%',
      padding: theme.spacing(3.5, 0),
      marginTop: theme.spacing(20),
    },
  }),
);
