import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  container: {
    margin: 'auto',
    maxWidth: 450,
    width: '100%',
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(10),
    },

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(6),
    },
  },
  titleText: {
    fontSize: 30,
    fontWeight: 700,
    marginTop: 10,
  },
  divider: {
    paddingTop: theme.spacing(10),
  },
  text: {
    marginTop: 10,
  },
  endAdornment: {
    background: '#F0F3FB',
    borderRadius: '0px 4px 4px 0px',
    right: '0%',
    alignItems: 'center',
    justifyContent: 'center',
  },
  input: {
    marginTop: 10,
  },
  inputText: {
    marginTop: 30,
    color: '#666666',
  },
  button: {
    marginTop: 20,
    textTransform: 'none',
    padding: theme.spacing(3.5, 0),
  },
  loginButton: {
    marginTop: 20,
    textTransform: 'none',
  },
  buttonSingularLogin: {
    width: '100%',
    height: '100%',
    fontSize: '17px',
    lineHeight: '22px',
    padding: theme.spacing(3, 1.5),
    borderRadius: 10,
    margin: 0,
  },
  defaultDisabled: { backgroundColor: '#DFE1E3' },
  link: {
    color: '#740290',

    //TODO: remove when have variants:
    fontWeight: 600,
    fontSize: '14px',
    lineHeight: '18px',
  },
}));
