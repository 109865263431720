import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  verifyButton: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(36.5),
    },

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(59),
      padding: theme.spacing(1.25, 8.5),
    },
  },

  footer: {
    [theme.breakpoints.up('sm')]: {
      position: 'absolute',
      bottom: theme.spacing(6),

      '& p:first-of-type': {
        marginBottom: theme.spacing(3),
      },
    },

    [theme.breakpoints.down('xs')]: {
      '& p:first-of-type': {
        marginBottom: theme.spacing(7),
      },
    },
  },

  otpContainerActive: {
    '& input': {
      borderColor: '#740290 !important',
    },
  },

  otpContainerRtl: {
    justifyContent: 'flex-end',
  },

  otpContainerError: {
    '& input': {
      borderColor: '#BB0038',
    },
  },

  otpLabel: {
    //TODO remove and uncomment when palette is added
    color: '#666666',
    // color: theme.extraOptions.newPallette.Grey,
    marginBottom: theme.spacing(1),
  },

  otpLabelSofiClient: {
    marginTop: theme.spacing(2),
  },
  credentialsContainer: {
    marginTop: theme.spacing(0),
  },
  credentialsError: {
    color: theme.extraOptions.newPallette.Red,
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    alignItems: 'right',
    letterSpacing: '-0.2px',
  },
  credentialsLink: {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: '18px',
    color: '#374A99',
    textDecoration: 'none',
    alignItems: 'right',
    letterSpacing: '-0.2px',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  otpCell: {
    fontSize: 23,
    fontWeight: 600,
    lineHeight: '45px',

    borderRadius: 5,
    height: 50,
    border: '1px solid #DFE1E3',
    boxSizing: 'border-box',
    outline: 'none',
    width: '100% !important',
  },

  sofiClientContainer: {
    width: 'inherit',
  },

  otpWizard: {
    maxWidth: 46,
    margin: theme.spacing(0, 0.5),
  },

  otpSofiClientInputContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },

  otpSofiClientCell: {
    maxWidth: 56,
  },
}));
