import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnBox, TextVariants, Text, Button } from '@retrain-ai/ui-design';
import { RoleType } from '../..';
import { FloatingCard } from '../../../../components/floating-card/floating-card';
import { FooterText } from '../../../../components/footer-text/footer-text';
import { useStyles } from './VehadartaLayout.styles';
import imageSrc from '../../../../images/vehadarta-signin.png';
import { ReactComponent as SkeelzLogoIcon } from '../../../../images/skeelz-logo.svg';
import { Box } from '@mui/material';

type VehadartaInLayoutType = {
  onSelect: (role: RoleType) => void;
};

export const VehadartaLayout: FC<VehadartaInLayoutType> = ({ onSelect }) => {
  const {classes: s } = useStyles();
  const { t } = useTranslation();

  return (
    <FloatingCard bottomLinkText={<FooterText />} bottomLinkHref={'https://www.retrain.ai'} imageSrc={imageSrc}>
      <ColumnBox className={s.container} alignItems="center" data-testid="skeelz-container">
        <Box textAlign="center">
          <SkeelzLogoIcon />
        </Box>

        <Text variant={TextVariants.PageTitle} className={s.titleText} align="center">
          {t('login.vehadarta-title')}
        </Text>

        <Button onClick={() => onSelect('Candidate')} className={s.button} size="large" data-testid="system-entry-candidate-button">
          <Text variant={TextVariants.SubtitleSemiBold}>{t('login.system-entry')}</Text>
        </Button>

        <Text
          align="center"
          onClick={() => onSelect('Recruiter')}
          variant={TextVariants.RegularTitle}
          className={s.recruitersEntryButton}
          data-testid="recruiters-entry-button"
        >
          {t('login.recruiters-entry')}
        </Text>
      </ColumnBox>
    </FloatingCard>
  );
};
