import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    content: {
      fontSize: 16,

      '& .c1, & .c12, & .c17': {
        fontWeight: 700,
      },

      '& .lst-kix_list_4-0': {
        paddingLeft: 30,
        listStyle: 'none',
      },

      '& .lst-kix_list_4-1': {
        paddingLeft: 50,
      },

      '& .lst-kix_list_4-2': {
        paddingLeft: 70,
      },

      '& .lst-kix_list_4-3': {
        paddingLeft: 90,
      },

      '& .c3': {
        textDecoration: 'underline',
      },

      '& li': {
        marginBottom: theme.spacing(2),
      },
    },
  }),
);
