import * as yup from 'yup';

export const validate = (t: Function) => ({
  email: yup.string().email(t('validation.invalid-email')).required(t('validation.required')),
  phone: yup
    .string()
    // 10 phone digits, 1-4 code's digits
    .matches(/^\+?[0-9]{11,14}$/, t('validation.invalid-phone-number'))
    .required(t('validation.required')),
});
