import { AuthApi, Configuration, InfoApi } from '@retrain-ai/sign-in-manager-api';
import { AxiosError } from 'axios';
const config = new Configuration({ basePath: window.location.origin });
export const signInInfoApi = new InfoApi(config);
export const authApi = new AuthApi(config);

export const getSignInfo = async () => {
  const response = await signInInfoApi.signInInfoControllerGetWorkspaceInfo().catch((e) => {
    const error: AxiosError = e;
    if (error.response?.status === 404) {
      return undefined;
    } else {
      throw e;
    }
  });

  if (!response) {
    return;
  }

  const { data } = response;
  return data;
};

export const navigateToFallbackUrl = () => {
  window.location.href = 'https://app.retrain.ai';
};
