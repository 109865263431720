import React, { PropsWithChildren, useEffect } from 'react';
import i18n from './i18n';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import rtlPlugin from 'stylis-plugin-rtl';

export function useRtl() {
  const lang = i18n.language;

  return lang === 'he';
}

export const muiCache = (rtl: boolean) => {
  return createCache({
    key: 'sign-in-client',
    prepend: true,
    stylisPlugins: rtl ? [rtlPlugin] : [],
  });
};

export function RTL(props: PropsWithChildren<{}>) {
  const rtl = useRtl();

  useEffect(() => {
    if (rtl) {
      document.documentElement.setAttribute('dir', 'rtl');
    } else {
      document.documentElement.setAttribute('dir', 'ltr');
    }
  }, [rtl]);

  if (rtl) {
    return <CacheProvider value={muiCache(rtl)}>{props.children}</CacheProvider>;
  } else {
    return <React.Fragment>{props.children}</React.Fragment>;
  }
}
