import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  input: {
    [theme.breakpoints.up('sm')]: {
      maxWidth: 436,
      width: '100%',
    },

    '& input': {
      borderColor: theme.extraOptions.newPallette.Grey,
      fontSize: 16,
      fontWeight: 600,
      lineHeight: '22px',
      padding: theme.spacing(3.5, 4),
      outline: 'none',

      '&:focus': {
        // borderColor: theme.extraOptions.newPallette.Purple,
      },
    },
  },
  checkbox: {
    paddingBottom: theme.spacing(3),
  },
  submitSofiClientOtp: {
    textTransform: 'none',
    width: '100%',
    padding: theme.spacing(3.5, 0),
    marginTop: theme.spacing(5),

    '&:hover.Mui-disabled': {
      backgroundColor: theme.extraOptions.newPallette.LightGrey,
    },
  },

  rtlPhoneNumber: {
    marginRight: theme.spacing(1),
    marginLeft: theme.spacing(0),
  },
}));
