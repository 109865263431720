import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    latinFont: {
      fontFamily: 'Inter, Helvetica, Arial, sans-serif',
      fontWeight: 600,
    },

    footerContainer: {
      '& p': {
        color: theme.extraOptions.newPallette.MediumGrey,
      },
    },

    footerLogo: {
      marginLeft: theme.spacing(1.75),
      marginRight: theme.spacing(1),
    },
  }),
);
