import { UICustomizations } from '@retrain-ai/sofi-customization-api';
import { createContext, FC, PropsWithChildren, useContext, useState } from 'react';
import { useEffectOnce } from 'react-use';
import { customizationApi } from './customization.api';

export const CustomizationContext = createContext<UICustomizations>({});

// TODO: Move to sofi-utils?
export const CustomizationContextProvider: FC<PropsWithChildren<{}>> = ({ children }) => {
  const [loading, setLoading] = useState(true);
  const [customization, setCustomization] = useState<UICustomizations>({});

  useEffectOnce(() => {
    customizationApi
      .signInCustomizationControllerGetCustomization()
      .then(({ data }) =>
        setCustomization({
          ...(data as any),
        }),
      )
      .catch((e) => console.error(e))
      .finally(() => setLoading(false));
  });

  if (loading) {
    return null;
  }

  return <CustomizationContext.Provider value={customization}>{children}</CustomizationContext.Provider>;
};

export const useCustomization = () => {
  const context = useContext(CustomizationContext);

  if (context === undefined) {
    throw new Error('useCustomization must be used within a CustomizationContextProvider');
  }

  return context;
};
