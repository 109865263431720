import { FBox, Text, TextVariants } from '@retrain-ai/ui-design';
import { ReactComponent as RetrainSmallLogo } from '../../images/retrain-logo-small.svg';
import { useStyles } from './footer-text.style';

export const FooterText = () => {
  const { classes: s } = useStyles();

  return (
    <div dir="ltr">
      <FBox className={s.footerContainer}>
        <Text variant={TextVariants.Tag} className={s.latinFont}>
          Powered by
        </Text>
        <RetrainSmallLogo className={s.footerLogo} />
        <Text variant={TextVariants.Tag} className={s.latinFont}>
          retrain.ai
        </Text>
      </FBox>
    </div>
  );
};
