import { FormHelperText, Link, OutlinedInput } from '@mui/material';
import { ColumnBox, FBox, SimpleConfirmDialog, Text, Button, TextVariants, Divider } from '@retrain-ai/ui-design';
import { useTranslation } from 'react-i18next';
import { FloatingCard } from '../../components/floating-card/floating-card';
import { useStyles } from './login.style';
import { useState } from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { authApi } from './login.api';
import { LoginButton } from '../../components/login-button/login-button';
import { LoginProperties } from './types';
import { ReactComponent as RetrainLogo } from '../../images/retrain-logo.svg';
import { CircularProgress } from '@mui/material';
import { FooterText } from '../../components/footer-text/footer-text';
import { useEffectOnce } from 'react-use';
import { useCustomization } from '../../components/customization/customization';
import { theme } from '../../theme';

export const Login: React.FC<LoginProperties> = (props: LoginProperties) => {
  const { t } = useTranslation();
  const { signInTitle } = useCustomization();

  const { classes: s, cx } = useStyles();
  const { oktaAuth } = useOktaAuth();
  const [invalidLogin, setInvalidLogin] = useState<boolean>(false);
  const [forgotPassword, setForgotPassword] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  const { isUserAndPassAvailable, buttonSignInInfoOptions, autoSignIn } = props;
  const showDivider = isUserAndPassAvailable && buttonSignInInfoOptions.length > 0;
  useEffectOnce(() => {
    if (autoSignIn) {
      handleAutoSignIn();
    }
  });

  const handleAutoSignIn = async () => {
    const { idp } = buttonSignInInfoOptions[0];
    const redirectUrl = new URL(window.location.href).searchParams.get('redirectUrl');

    const {
      data: { authorizationUrl },
    } = await authApi.authControllerSignIn({ idp: idp ?? '' }, { params: { redirectUrl } });

    setLoading(false);
    window.location.href = authorizationUrl;
  };

  const handleLogin = async () => {
    setLoading(true);
    try {
      setInvalidLogin(false);
      const { sessionToken } = await oktaAuth.signInWithCredentials({ username, password });
      const redirectUrl = new URL(window.location.href).searchParams.get('redirectUrl')!;
      const {
        data: { authorizationUrl },
      } = await authApi.authControllerSignIn({ sessionToken }, { params: { redirectUrl } });

      window.location.href = authorizationUrl;
      setLoading(false);
    } catch (e) {
      setInvalidLogin(true);
      console.log('Okta login error occurred', e);
      setLoading(false);
    }
  };
  return (
    <FloatingCard bottomLinkText={<FooterText />} bottomLinkHref={'https://www.retrain.ai'}>
      <ColumnBox width={'100%'} className={s.container}>
        <ColumnBox width={'100%'} alignItems={'center'} justifyContent="center">
          <RetrainLogo
            style={{
              width: '18vw',
            }}
          />
          <Text
            sx={{ textAlign: 'center', paddingTop: theme.spacing(2), whiteSpace: 'pre-wrap' }}
            variant={TextVariants.RegularTitle}
          >
            {signInTitle ?? t('login.title')}
          </Text>
        </ColumnBox>
        <ColumnBox width={'100%'} height={'100%'}>
          {!isUserAndPassAvailable ? undefined : (
            <ColumnBox>
              <Text className={s.inputText}>{t('login.email.text')}</Text>
              <OutlinedInput
                placeholder={t('login.email.text-placeholder')}
                size="medium"
                color="secondary"
                className={s.input}
                onChange={({ target }) => setUsername(target.value)}
              />
              <FBox justifyContent="space-between" className={s.inputText}>
                <Text>{t('login.password.text')}</Text>
                <Link href="#" className={s.link} onClick={() => setForgotPassword(true)}>
                  {t('login.password.forgot-password')}
                </Link>
              </FBox>
              <OutlinedInput
                placeholder={t('login.password.text-placeholder')}
                size="medium"
                color="secondary"
                type="password"
                className={s.input}
                onChange={({ target }) => setPassword(target.value)}
              />
              <Button className={s.button} disabled={isLoading} onClick={() => handleLogin()} size="large">
                {isLoading ? (
                  <CircularProgress size={20} color={'secondary'}></CircularProgress>
                ) : (
                  <Text variant={TextVariants.SubtitleSemiBold}>{t('login.login-button')}</Text>
                )}
              </Button>
            </ColumnBox>
          )}
          {invalidLogin ? (
            <FormHelperText error={invalidLogin}>{t('login.error')}</FormHelperText>
          ) : (
            <div style={{ height: 20 }}></div>
          )}
          {showDivider && (
            <Divider
              classes={{
                divider: s.divider,
              }}
            >
              {t('login.login-divider')}
            </Divider>
          )}
          <FBox justifyContent="space-around">
            {buttonSignInInfoOptions.map((option) => (
              <LoginButton
                id={option.id}
                key={option.id}
                idp={option.idp ?? ''}
                text={option.text}
                method={option.method}
                className={cx(s.loginButton, buttonSignInInfoOptions.length === 1 ? s.buttonSingularLogin : undefined)}
              ></LoginButton>
            ))}
          </FBox>
        </ColumnBox>
      </ColumnBox>
      <SimpleConfirmDialog
        isOpen={forgotPassword}
        title={t('login.password.forgot-password-title')}
        body={t('login.password.forgot-password-text')}
        onClose={() => setForgotPassword(false)}
      />
    </FloatingCard>
  );
};
