import { SyntheticEvent } from 'react';
import { useFormikContext } from 'formik';
import { DestinationValuesProps, RegistrationProps } from '../types';
import { useTranslation } from 'react-i18next';
import { useStyles } from './RegistrationField.style';
import { useCommonStyles } from '../Otp.style';
import { Divider } from '../../../../components/divider/divider';
import { Checkbox, Input, TextVariants, Text, Button, PhoneNumber, ColumnBox } from '@retrain-ai/ui-design';
import { useRtl } from '../../../../RTL';

export const RegistrationFields: React.FC<RegistrationProps> = ({ setError, isSubmitting, disabled }) => {
  const { t } = useTranslation();
  const { classes: s, cx } = useStyles();
  const { classes: c } = useCommonStyles();
  const rtl = useRtl();

  const { handleChange, values, setFieldValue, submitForm, errors } = useFormikContext<DestinationValuesProps>();

  const onCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setError('');
    const { id } = e.target || {};

    const isPhoneChecked = values?.isPhone;
    const isEmailChecked = values?.isEmail;

    if (id === 'isPhone' && isEmailChecked) {
      setFieldValue('isPhone', !values?.isPhone);
      setFieldValue('isEmail', false);
      setFieldValue('email', '');
    }

    if (id === 'isEmail' && isPhoneChecked) {
      setFieldValue('isEmail', !values?.isEmail);
      setFieldValue('isPhone', false);
      setFieldValue('phone', '');
    }
  };

  const onChange = (_value: string, e?: SyntheticEvent) => {
    handleChange(e);
    setError('');
  };

  const onPhoneNumberChange = (value: string) => {
    setFieldValue('phone', value);
    setError('');
  };

  const textFieldClasses = { component: s.input, title: '', error: c.errorText };
  const hasValidationPhoneError = values.isPhone && !!errors?.phone;
  const hasValidationEmailError = values.isEmail && !!errors?.email;
  const isButtonDisabled = hasValidationPhoneError || hasValidationEmailError || isSubmitting || disabled;

  return (
    <ColumnBox>
      <Checkbox
        className={s.checkbox}
        onChange={onCheckboxChange}
        label={t('otp.phone')}
        id="isPhone"
        checked={values.isPhone}
        variant="round"
        data-testid="isPhone"
        disabled={disabled}
      />

      {values?.isPhone && (
        <PhoneNumber
          showError={hasValidationPhoneError}
          errorText={errors?.phone as string}
          name="phone"
          onChange={onPhoneNumberChange}
          value={values?.phone}
          data-testid="phone"
          title=""
          disabled={disabled}
          classes={{
            component: cx({ [s.rtlPhoneNumber]: rtl }),
          }}
        />
      )}

      <Divider containerClass={c.dividerContainer}>{t('otp.channel-divider')}</Divider>

      <Checkbox
        className={s.checkbox}
        onChange={onCheckboxChange}
        label={t('otp.email')}
        id="isEmail"
        checked={values.isEmail}
        variant="round"
        data-testid="isEmail"
        disabled={disabled}
      />

      {values?.isEmail && (
        <Input
          isError={hasValidationEmailError}
          errorText={errors?.email as string}
          type="text"
          name="email"
          placeholder={t('otp.enter-email')}
          onChange={onChange}
          value={values?.email}
          testId="email"
          disabled={disabled}
        />
      )}

      <Button
        onClick={submitForm}
        className={s.submitSofiClientOtp}
        disabled={isButtonDisabled}
        data-testid="send-code-button"
        size="large"
      >
        <Text variant={TextVariants.SubtitleSemiBold}>{t('otp.send-code')}</Text>
      </Button>
    </ColumnBox>
  );
};
