import { useStyles } from './terms-of-use.style';

export const TermsOfUse = () => {
  const { classes: s } = useStyles();

  return (
    <div className={s.content} dir="ltr">
      <h1 className="c27">
        <span className="c7">RETRAIN</span>
      </h1>
      <h1 className="c27">
        <span className="c7">SERVICE TERMS OF USE </span>
      </h1>
      <p className="c10">
        <span className="c11">
          The following are the terms and conditions that apply to the use of the Services (as defined below) offered
          and provided by{' '}
        </span>
        <span className="c6">Retrain AI Inc., </span>
        <span className="c11">
          a company incorporated under the laws of Delaware with principal mailing address at 489 Fifth Avenue, Floor
          28, 10017, New York, United States, and any of its affiliates (“
        </span>
        <span className="c6">Retrain</span>
        <span className="c1">”). </span>
      </p>
      <p className="c10">
        <span className="c11">The use of, and access to the Retrain website at </span>
        <span className="c31 c11">
          <a
            className="c20"
            href="https://www.google.com/url?q=https://www.retrain.ai/&amp;sa=D&amp;source=editors&amp;ust=1661346818573310&amp;usg=AOvVaw3JoBq0oL_P6YiP-LaLffxv"
          >
            https://www.retrain.ai/
          </a>
        </span>
        <span className="c11">&nbsp;(the “</span>
        <span className="c6">Website</span>
        <span className="c11">”), and its products and services (the "</span>
        <span className="c6">Retrain Platform</span>
        <span className="c11">", and together with the Website, the "</span>
        <span className="c6">Service</span>
        <span className="c11">") made available to you (the "</span>
        <span className="c6">Client</span>
        <span className="c11">" or “</span>
        <span className="c6">you</span>
        <span className="c11">” or “</span>
        <span className="c6">your</span>
        <span className="c11">
          ”) or any employee, independent contractor, and agent who are authorized by such Client to access and use the
          Service, each of which whom shall agree to be bound by these Terms (collectively, “
        </span>
        <span className="c6">User(s)</span>
        <span className="c1">”). </span>
      </p>
      <p className="c10">
        <span className="c11">
          A “User” is any individual or entity who accesses the Service in any capacity. The use and access to the
          Service by Users are conditioned upon Client and Users’ acceptance, without any modification, of the terms of
          use set forth herein, including the terms of the{' '}
        </span>
        <span className="c11 c24">Privacy Policy</span>
        <span className="c11">&nbsp;which are incorporated herein by reference (and together, the "</span>
        <span className="c6">Terms</span>
        <span className="c1">"). </span>
      </p>
      <p className="c10">
        <span className="c1">
          The Terms herein govern the access to, and use of, the Service and constitute a binding legal agreement
          between the Client (and any of the Users accessing the Service) and Retrain. When the access to the Services
          is made available to a User by the Client, then (a) all references to “User” as a contracting entity under the
          Terms are also references to the Client, (b) Retrain may provide the Client with the ability to access, use,
          remove, retain, and control User’s Account ID and related User Content, (c) User’s access to Services is
          governed by these Terms, and (d) Retrain may provide User’s personal information to the Client.{' '}
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          These Terms apply regardless of how User access the Service, including any technologies or devices by which
          Retrain makes the Services available. By clicking on the “I accept” button, User hereby waives any applicable
          rights to require an original (non-electronic) signature or delivery or retention of non-electronic records,
          to the extent not prohibited under applicable law.{' '}
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          User must read these Terms carefully. By accessing the Services, User hereby confirms that he/she has read,
          understood, and agreed to these Terms in their entirety.{' '}
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          If User does not agree to these Terms in their entirety, please do not use or access the Service.
        </span>
      </p>
      <p className="c10">
        <span className="c1">
          Retrain may, at any time, cease providing any, or all, of the Services at its sole discretion and without
          notice.{' '}
        </span>
      </p>
      <p className="c10">
        <span className="c11">
          Any User which accepts or agrees to these Terms on behalf of a corporation or any other legal entity (the "
        </span>
        <span className="c6">Legal Entity</span>
        <span className="c1">
          "), represents and warrants that it has the authority to bind that certain Legal Entity to these Terms which
          shall apply to that certain Legal Entity.
        </span>
      </p>
      <p className="c10" id="h.gjdgxs">
        <span className="c11">Retrain and User may each be referred to herein as a “</span>
        <span className="c6">Party</span>
        <span className="c11">” and collectively as the “</span>
        <span className="c6">Parties</span>
        <span className="c1">”. </span>
      </p>
      <ol className="c15 lst-kix_list_1-0 start" start={1}>
        <li className="c10 c18 li-bullet-0">
          <span className="c16 c2 c12 c19">SERVICES; ACCOUNTS </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">Retrain offers access to Retrain’s </span>
          <span className="c11">
            online platform which uses AI to perform employees’ skills and qualifications assessment and generate
            invaluable data on jobs and workforce retraining for organizations
          </span>
          <span className="c1">.</span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c1">
            To use the Service, you must be a human. Accounts registered by other automated tools such as bots are not
            permitted.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            To use the Service, (a) Client shall provide Retrain with certain identifiable information regarding its
            authorized Users (e.g., his/her email address, telephone/mobile number, job title, seniority, name of
            supervisor, etc. and set a password for the User to access{' '}
          </span>
          <span className="c11">his/her</span>
          <span className="c2">&nbsp;personal </span>
          <span className="c11">dashboard)</span>
          <span className="c2">&nbsp;as required by Retrain (the “</span>
          <span className="c2 c12">User Data</span>
          <span className="c2">
            ”); and (b) Retrain shall provide the Client with access to a designated web portal managed through an admin
            account (the “
          </span>
          <span className="c2 c12">Admin</span>
          <span className="c2">&nbsp;</span>
          <span className="c2 c12">Account</span>
          <span className="c2">
            ”) to be operated by authorized representative(s) appointed by the Client in its sole discretion (the “
          </span>
          <span className="c2 c12">Admin</span>
          <span className="c1">”). </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c2">
            Client will use the Admin Account to specify one or more Admins who will have the rights to access Admin
            Account(s), and Users’ accounts (the “
          </span>
          <span className="c2 c12">Users Accounts</span>
          <span className="c2">” and together with the Admin Account, the “</span>
          <span className="c2 c12">Accounts</span>
          <span className="c1">
            ”) and communicate with Retrain regarding the operation of the Service on behalf of the Client or any of the
            Users.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c1">
            The Admin will manage its and any of the Users’ use of the Services, on behalf of the Client. The Admin
            shall create User IDs and passwords for Users to access and use the Services and manage Users’ access and
            use of the Service.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">As between Client and the Users, Client is solely responsible for: (</span>
          <span className="c2 c12">a</span>
          <span className="c2">
            ) maintaining the confidentiality and security of the Accounts and associated passwords and usernames; (
          </span>
          <span className="c2 c12">b</span>
          <span className="c2">) any use of the Accounts; (</span>
          <span className="c2 c12">c</span>
          <span className="c2">
            ) the content (including the accuracy) of such information and or any other material (including but not
            limited to, images, personal information, etc.) made available by Client or any of the Users through the
            Services, for example, information retrieved from User’s LinkedIn accounts or CVs, etc. (collectively, “
          </span>
          <span className="c2 c12">User Content</span>
          <span className="c2">”); (</span>
          <span className="c2 c12">d</span>
          <span className="c2">) Admin and Users’ compliance with these Terms; (</span>
          <span className="c2 c12">e</span>
          <span className="c2">
            ) ensuring that Client, Admin, or any of the Users, will not use the Services to disseminate any material
            that is infringing, obscene, threatening, libelous, or otherwise unlawful or tortious material, including
            material that is harmful to children or violates the rights of any third party; and (
          </span>
          <span className="c2 c12">f</span>
          <span className="c1">
            ) obtaining any and all right and authority necessary to (i) act on behalf of Users and bind them to these
            Terms, (ii) access and use the Services, and (iii) provide, access, transmit, and use User Content or any
            other User Data as contemplated under the Terms. Client agrees that Retrain’s responsibilities do not extend
            to the internal management or administration of the Services for Client or any User.
          </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c1">
            It is User’s responsibility to always maintain: (a) his/her password and Account information in strict
            confidence; and (b) backups of any information uploaded to his/her Account, including any User Content.
            Retrain shall not be liable to the Users, Client or any person for any loss or damage which may arise as a
            result of any failure by the User to protect his/her password or account information or maintain such
            backups.
          </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c0">Suspension and Deletion.</span>
          <span className="c1">
            &nbsp;If Retrain is, or becomes, suspicious activity channeled through any Account, Retrain reserves the
            right to delete or suspend access to such Account. Retrain may suspend or limit User’s access to the
            Services, or remove or disable any User Account or User Content, if (a) continued User access or hosting of
            User Content may result in material harm to the Services, Users or any third party (b) Retrain receives a
            judicial or other governmental order or request that, in Retrain’s good faith belief, requires Retrain to do
            so, or (c) Retrain reasonably and in good faith believes continued access or hosting of User Data or User
            Content violates these Terms or any applicable law. Retrain will endeavor to notify Client before any
            suspension or limitation, except when Retrain reasonably believes that applicable law prevents it from doing
            so or when immediate action would better prevent imminent harm to the Services, Users, or a third party.
            Retrain will narrow a suspension or limitation in time and scope as is reasonably practicable under the
            circumstances. &nbsp; &nbsp;{' '}
          </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c2">Please see Retrain’s </span>
          <span className="c11 c24">Privacy Policy</span>
          <span className="c11">&nbsp;</span>
          <span className="c1">for more information on how Retrain uses and stores personal information.</span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c2">
            User may update or correct his/her details at any time by going to his/her User Account’s profile or by
            sending the Admin a request to change its details, depending on the nature of such change requested.{' '}
          </span>
          <span className="c11">User </span>
          <span className="c1">
            hereby confirms that all the data provided by him/her to Retrain is true, accurate and up to date in all
            respects and at all times.
          </span>
        </li>
        <li className="c4 li-bullet-3">
          <span className="c1">
            User understands that Retrain does not offer any warranty or representation that the use of the Service will
            achieve any particular result. The User further agrees and acknowledges that the Service is not intended to
            be used as the sole basis for any business decision.
          </span>
        </li>
        <li className="c4 li-bullet-4">
          <span className="c1">
            User acknowledges that Retrain assumes no responsibility and expressly disclaim all warranties of any kind
            as to the overall integrity and quality of information provided by, or to, the User, and that Retrain cannot
            guarantee the accuracy or timeliness of data presented through the Service, or any possible implications
            derived thereby.
          </span>
        </li>
        <li className="c10 c34 li-bullet-5">
          <span className="c0 c16 c28">Links To Third Party Websites.</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-2 start" start={1}>
        <li className="c10 c13 li-bullet-6">
          <span className="c11">
            Retrain may place links to other websites and resources provided by third parties via the Services
          </span>
          <span className="c2">&nbsp;that might offer to purchase goods or services</span>
          <span className="c11">
            , including links contained in advertisements, banner advertisements and sponsored links{' '}
          </span>
          <span className="c2">(the “</span>
          <span className="c2 c12">Third-Party Services</span>
          <span className="c2">”)</span>
          <span className="c11">. Such links are provided for User</span>
          <span className="c2">’s convenience</span>
          <span className="c11">&nbsp;only. If User </span>
          <span className="c2">decides to</span>
          <span className="c11">
            &nbsp;click on a link to a third-party websites and resources through the Service, User’s activity and use
            on the linked websites and resources is{' '}
          </span>
          <span className="c2">governed</span>
          <span className="c1">&nbsp;by that website’s policies, not by those of Retrain. </span>
        </li>
        <li className="c10 c13 li-bullet-6">
          <span className="c11">Retrain </span>
          <span className="c2">
            disclaims any and all liability or responsibility for any Third-Party Services accessed through the Service,
            including any third-party online property to which the Service links.{' '}
          </span>
          <span className="c11">Retrain does not endorse any </span>
          <span className="c2">Third-Party Services and if </span>
          <span className="c11">User decides to access or use any </span>
          <span className="c2">Third-Party Services it does </span>
          <span className="c11">so at its own risk and subject to the terms and conditions of use for such </span>
          <span className="c2">Third-Party Services</span>
          <span className="c11">. </span>
          <span className="c1">&nbsp;</span>
        </li>
        <li className="c10 c13 li-bullet-7">
          <span className="c1">
            Additional terms and conditions may apply to specific offers or features offered through the Service. All
            such additional terms and conditions are hereby incorporated by reference into these Terms.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={2}>
        <li className="c10 c18 li-bullet-0" id="h.tyjcwt">
          <span className="c2 c12">LICENSE </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c9 li-bullet-1" id="h.4i7ojhp">
          <span className="c2">
            Subject to the continued compliance with these Terms (including, for avoidance of any doubt, payment of the
            applicable fees by the Client), Retrain hereby grants, and Client hereby accepts, a limited, personal,
            non-transferable (other than to Users), non-exclusive, non-assignable license during the Term (as defined
            below), to access and use the Service solely for{' '}
          </span>
          <span className="c2 c3">Client’s internal business purposes</span>
          <span className="c2">&nbsp;(the "</span>
          <span className="c2 c12">License</span>
          <span className="c2">
            "). The License granted herein includes the right to access and use the Service, in accordance with the
            documentation provided by Retrain, including these Terms (the "
          </span>
          <span className="c2 c12">Materials</span>
          <span className="c1">").</span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c1 c3">
            Under the License granted to Client by Retrain, Client may not use the Retrain Platform to:
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-2 start" start={1}>
        <li className="c10 c13 li-bullet-7">
          <span className="c2 c3">
            Build a similar or competitive service; however, customed licenses may be available for resellers. Please
            contact us to{' '}
          </span>
          <span className="c11 c3">learn</span>
          <span className="c1 c3">&nbsp;more.</span>
        </li>
        <li className="c10 c13 li-bullet-6">
          <span className="c2 c3">
            Violate, in any way, any applicable federal, state, local, or international law or regulation (including,{' '}
          </span>
          <span className="c11 c3">without</span>
          <span className="c1 c3">
            &nbsp;limitation, any laws regarding the export of data or software to and from your jurisdiction or any
            other countries).
          </span>
        </li>
        <li className="c10 c13 li-bullet-8">
          <span className="c2 c3">
            Perform any action or access any data generated or made available through the Retrain Platform for
            commercial purposes or for purpose of{' '}
          </span>
          <span className="c11 c3">exploiting</span>
          <span className="c1 c3">, harming, or attempting to exploit or harm others in any way.</span>
        </li>
        <li className="c10 c13 li-bullet-7">
          <span className="c2 c3">Knowingly send, </span>
          <span className="c11 c3">receive</span>
          <span className="c1 c3">
            , upload, download, use, or re-use any material that does not comply with these Terms.
          </span>
        </li>
        <li className="c10 c13 li-bullet-8">
          <span className="c2 c3">
            Engage in any other conduct that restricts or inhibits anyone's use or enjoyment of the Retrain Platform, or{' '}
          </span>
          <span className="c11 c3">which</span>
          <span className="c1 c3">
            , as determined by us, may harm Retrain or users of the Retrain Platform or expose them to liability.
          </span>
        </li>
        <li className="c10 c13 li-bullet-6">
          <span className="c1 c3">
            In any manner that could disable, overburden, damage, or impair the site or interfere with any other party's
            use of the Retrain Platform, including their ability to engage in real time activities through the Retrain
            Platform.
          </span>
        </li>
        <li className="c10 c13 li-bullet-8">
          <span className="c1 c3">
            Use any manual process to monitor or copy any of the material on the Retrain Platform or for any other
            unauthorized purpose without Retrain’s prior written consent.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1" start={3}>
        <li className="c9 li-bullet-1" id="h.2xcytpi">
          <span className="c2">
            Retrain continuously makes efforts to improve the Service for the benefit of its clients, including the{' '}
          </span>
          <span className="c11">User</span>
          <span className="c2">
            . Retrain may, from time to time, develop enhancements, upgrades, updates, improvements, modifications,
            extensions and other changes to the Service or Materials including removing any feature or functionality of
            the Service (the “
          </span>
          <span className="c2 c12">Modifications</span>
          <span className="c1">
            ”). Retrain shall implement and incorporate any available Modifications into the Service in its sole
            discretion, and Client hereby authorizes Retrain, in advance, to implement and incorporate such
            Modifications into the Service, at any given time.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c1">
            Retrain has the right to monitor Users’ use of the Service, to inspect Users’ compliance with these Terms,
            take appropriate legal action, including referral to law enforcement, for any illegal or unauthorized use of
            the Service. terminate or suspend the License granted herein, to all or part of any of the Service for any
            or no reason, including any violation of these Terms.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={3}>
        <li className="c10 c18 li-bullet-0" id="h.30j0zll">
          <span className="c16 c2 c12 c19">MUTUAL REPRESENTATIONS AND WARRANTIES </span>
        </li>
      </ol>
      <p className="c26">
        <span className="c11">
          Each Party represents and warrants toward the other Party hereto that: (i) it has the full power, legal
          capacity, and authority to enter into, deliver and fully perform its respective obligations set forth in these
          Terms; (ii) the execution or performance of these Terms will not result in a violation or breach of any
          contract, agreement, policy, order, judgment, decree, rule, regulation or law to which such Party is bound;
          and (iii) unless
        </span>
        <span className="c1">
          &nbsp;otherwise expressly provided in these Terms, there are no third party beneficiaries intended under these
          Terms.
        </span>
      </p>
      <ol className="c15 lst-kix_list_1-0" start={4}>
        <li className="c10 c18 li-bullet-0" id="h.1fob9te">
          <span className="c16 c2 c12 c19">USER REPRESENTATIONS AND WARRANTIES </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c1">
            User represents and warrants that: (i) it will not use the Service for any illegal or unauthorized purpose
            or infringe or promote the infringement of any intellectual, proprietary or other right of any party, and
            User will comply with all applicable laws and regulations (including, but not limited to, all applicable
            import and export laws, copyright and privacy laws) in the User’s use of and access to the Service; (ii)
            User is the owner of, or has the required rights in all of the information which is uploaded to the Service;
            and (iii) User holds and maintains all personal information in compliance with any and all applicable laws.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c1 c3">
            User further represents and warrants that it: (a) has obtained and will maintain all rights, consents, and
            permissions necessary for using any User Content and/or accessing and using the Service, (b) acknowledges
            that the internet and telecommunications networks are inherently insecure and that Retrain will have no
            liability for any changes to, interception of, or loss of data while in transit via the internet or a
            telecommunications provider’s network. Beta offerings are provided “AS IS” and “as available” with no
            warranties and Retrain will have no liability and no obligation to indemnify for any beta offering
            whatsoever, and (c) User may only access and/or use any User Content provided through the Retrain Platform
            in accordance with the terms of the License.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c2">
            User will not, nor will User allow anyone acting on its behalf, or other third party to: (a) copy, modify,
            adapt, translate or otherwise create derivative works of the Service; (b) reverse engineer, de-compile,
            disassemble or otherwise attempt to discover the source code of the Service; (c) rent, lease, sell,
            sublicense, assign or otherwise transfer rights in or to the Service; (d) remove any proprietary notices or
            labels from the Service; (e) use, post, transmit or introduce any device, software or routine which
            interferes or attempts to interfere with the operation of the Service; (f) develop any other product or
            service containing any of the concepts and ideas contained in the Service or use the Service for the purpose
            of building a similar or competitive product; (g) test the Service or use the Service in connection with any
            benchmark tests, evaluation, or any other tests of which the results are designated or likely to be
            published in any form or media, or otherwise made available to the public, without Retrain prior written
            approval; (h) directly or indirectly take any action to contest Retrain’s intellectual property rights or
            infringe them in any way; (i) make the Service available for timesharing, service bureau or application
            service provider; (j) remove, obscure, or alter any notice of copyright, Retrain’s Marks (as such term is
            defined below), or other proprietary right appearing in or on any item included with the Service;{' '}
          </span>
          <span className="c2 c25">(k)</span>
          <span className="c1">
            &nbsp;allow a minor under the Age of Consent (as defined below) or any third party to have access to the
            Service without Retrain’s prior written consent.
          </span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c1">
            User understands that the materials and/or information appearing on the Retrain Platform may include
            technical, typographical, or photographic errors, and that Retrain does not warrant that any such
            materials/information are accurate, reliable, complete, or updated. User also understands that while Retrain
            may make changes to the materials and/or information appearing on the Retrain Platform, from time to time,
            it does not make any commitment to review, evaluate or update said materials and/or information.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={5}>
        <li className="c10 c18 li-bullet-0" id="h.3znysh7">
          <span className="c16 c2 c12 c19">INTELLECTUAL PROPERTY RIGHTS; USER CONTENT; USER DATA</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c9 li-bullet-1" id="h.3dy6vkm">
          <span className="c0 c16 c12">Retrain’s Intellectual Property Rights. </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-2 start" start={1}>
        <li className="c10 c17 li-bullet-9">
          <span className="c1">
            Retrain owns all right, title and interest in and to the Service and Materials as well as any derivative
            works of the Services and Materials and all physical embodiments of same, which Retrain may develop from
            time to time, (and excluding any third-party product(s) embodied in, utilized or bundled with the Service or
            Materials or User Content).{' '}
          </span>
        </li>
        <li className="c10 c17 li-bullet-9">
          <span className="c1">
            The Service and all intellectual property rights in the Service (including Retrain’s Marks and any
            derivative works of the Service) are, and shall remain, Retrain exclusive property, including but not
            limited to, any modifications or custom features to the Service to be developed by Retrain, whether
            requested or instructed by the Client or not, even if the Client has paid for such modifications, except if
            Retrain and Client have a separate written agreement that specifically states otherwise and references this
            section.&nbsp;
          </span>
        </li>
        <li className="c10 c17 li-bullet-10">
          <span className="c2">
            Any error and bug reports, additional features, ideas, requests, feedbacks, recommendations, comments,
            concepts and other requests or suggestions related to the Service (collectively, the “
          </span>
          <span className="c2 c12">Ideas</span>
          <span className="c1">
            ”) that the Users may provide to Retrain, will be solely owned by Retrain. Users hereby irrevocably assigns
            and transfers any intellectual property rights in such Ideas to Retrain, free of charge.{' '}
          </span>
        </li>
        <li className="c10 c17 li-bullet-11">
          <span className="c2">
            All of Retrain’s trademarks, including but not limited to, “Biz”, any service marks, logos, domain names,
            copyrights and other proprietary rights associated with Retrain and the Service, whether registered or
            non-registered, shall be collectively referred to as "
          </span>
          <span className="c2 c12">Retrain’s Marks</span>
          <span className="c2">
            ". User agrees not to directly or indirectly (and not to allow any third party to):{' '}
          </span>
          <span className="c2 c5">(a)</span>
          <span className="c2">
            &nbsp;use Retrain’s Marks for any purpose (other than as detailed hereunder) without Retrain’s express
            written consent; and{' '}
          </span>
          <span className="c2 c5">(b)</span>
          <span className="c1">
            &nbsp;register, attempt to register, or assist anyone else to register any Retrain’s Marks or marks
            confusingly similar thereto. Notwithstanding the aforementioned, User agrees to update Retrain, in advance,
            with respect to any public display of any feature related to Retrain or the Services.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1" start={2}>
        <li className="c9 li-bullet-2">
          <span className="c0 c16 c12">User Data; User Content </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-2 start" start={1}>
        <li className="c10 c17 li-bullet-12">
          <span className="c1">
            User is and shall remain the sole and exclusive owner of any User Data made available to Retrain, or any
            User Content uploaded to his/her Account. Nothing contained herein can serve as a basis to suggest or imply
            that Retrain is the author of or otherwise responsible for any of the User Data or User Content. User must
            not use the Service in connection with any content that violates the rights of any third parties, or any
            activities where the use or failure of the Service could lead to death, personal injury or property or
            environmental damage or adversely impact or impose liability on Retrain in any manner.
          </span>
        </li>
        <li className="c10 c17 li-bullet-9">
          <span className="c1">
            User is, and will be at all times, fully and solely responsible for any and all activities that may occur
            while User is accessing or using the Service, including any and all information, materials, communications,
            text, graphics, links, electronic art, animations, illustrations, artwork, audio clips, video clips, photos,
            images, reviews, ideas and other data, materials, and content, including the selection and/or arrangements
            thereof, that User creates or uses while accessing and using the Service.{' '}
          </span>
        </li>
        <li className="c10 c17 li-bullet-11">
          <span className="c1">
            User acknowledges that Retrain does not monitor the User Content and will not provide any notice to User
            with respect to any such content. Retrain is not, and shall not be, responsible for any User Content of any
            user. If User has identified any issue or maintain other claims, including in relation to third party’s User
            Content, User shall settle any such problem or claim directly with such third party, and may not sue Retrain
            regarding such issue. Without derogating from the generality of the aforementioned, in the event that a User
            has a claim related to User Content, it may issue Retrain with a Copyright Infringement Notice (as such term
            is defined below), in accordance with the provisions set forth in Section ‎5.3 below.{' '}
          </span>
        </li>
        <li className="c10 c17 li-bullet-9">
          <span className="c1">
            By using the Service, the User hereby grants Retrain an irrevocable, non-exclusive, royalty-free, perpetual,
            worldwide, transferable, sublicensable right and license to use and access, use, reproduce, transmit, adapt,
            modify, perform, display, distribute, translate, publish and create derivative works based on the User
            Content, in whole or in part, in any media, for the purpose of operating and advertising the Service.{' '}
          </span>
        </li>
        <li className="c10 c17 li-bullet-12">
          <span className="c2">
            Client shall be solely responsible to ensure that any User who uses the Service, have granted Retrain the
            right to use and access their User Data
          </span>
          <span>&nbsp;</span>
          <span className="c1">as described under these Terms. </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1" start={3}>
        <li className="c9 li-bullet-2" id="h.1ci93xb">
          <span className="c0 c12 c16">Copyright Infringement Reporting.</span>
        </li>
      </ol>
      <p className="c10 c32">
        <span className="c2">
          If a User believes that any User Content or other content displayed through the Service, has been copied in a
          way that violates its copyrights (in this Section a “
        </span>
        <span className="c2 c12">Reporting Party</span>
        <span className="c2">”), such Reporting Party should contact Retrain immediately by sending an email to </span>
        <span className="c31">
          <a className="c20" href="mailto:info@retrain.ai">
            privacy@retrain.ai
          </a>
        </span>
        <span className="c2">&nbsp;(a “</span>
        <span className="c2 c12">Copyright Infringement Notice</span>
        <span className="c1">”). A Copyright Infringement Notice should include the following information:</span>
      </p>
      <ol className="c15 lst-kix_list_1-2" start={6}>
        <li className="c10 c17 li-bullet-13">
          <span className="c1">
            Reporting Party’s statement according to which it has identified content that is displayed through the
            Service and infringes its copyright, stating that (i) its statement is made under penalty of perjury, and
            (ii) the information provided under the Copyright Infringement Notice is accurate and that it is the
            copyright owner. If the Reporting Party is an authorized entity acting on behalf of the copyright owner in
            question, a duly executed power of attorney must be included in a form which is acceptable to Retrain.{' '}
          </span>
        </li>
        <li className="c10 c17 li-bullet-14">
          <span className="c1">
            A description of the copyrighted content that the Reporting Party claims has been infringed, and any
            relevant further details, such as the title and date of publication, and the location of the content that
            the Reporting Party claims is infringing is located on Retrain’s Services (e.g., by providing a URL or
            screen shot) as applicable.
          </span>
        </li>
        <li className="c10 c17 li-bullet-12">
          <span className="c1">
            Reporting Party’s contact details including mailing address, telephone number, and email address and any
            additional reasonable information that Retrain may require in order to authenticate the Reporting Party’s
            identity.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1" start={4}>
        <li className="c9 li-bullet-2">
          <span className="c1">
            If Retrain reasonably determines that any content that is displayed through the Service constitute a
            copyright infringement, or may cause harm to Retrain, User or third parties, Retrain may remove or take down
            such content or any part thereof.{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={6}>
        <li className="c10 c18 li-bullet-0">
          <span className="c16 c2 c12 c19">TERM AND TERMINATION</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c9 li-bullet-2" id="h.3whwml4">
          <span className="c1">
            Client and any of its Users will gain access to the Retrain Service (as applicable) on a temporary basis,
            for the duration of the subscription period agreed upon between Retrain and the Client in writing, and so
            long as Client meets its contractual obligations towards Retrain as provided therein.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-2" id="h.1t3h5sf">
          <span className="c11">
            Retrain reserve the right to suspend, withdraw, terminate, discontinue or modify, at any time, any Service
            or policy, without obligation to Client. If Retrain decides to suspend, withdraw, terminate, discontinue or
            modify any of the existing Services during the applicable s
          </span>
          <span className="c2">ubscription period, it will</span>
          <span className="c11">&nbsp;issue a notice as for the date of such withdrawal or termination.</span>
        </li>
        <li className="c9 li-bullet-2">
          <span className="c0 c3">Consequence of Termination</span>
          <span className="c2 c3">
            . Upon expiration or termination of the engagement between Retrain and Client, or upon Retrain's written
            request, Client shall, and shall instruct any User or third party acting on its behalf, to cease to process
            any data that was made available through the Retrain Platform, and within a reasonable{' '}
          </span>
          <span className="c11 c3">period</span>
          <span className="c1 c3">
            &nbsp;will at the request of Retrain: (1) return such data to Retrain; or (2) securely and completely
            destroy or erase all such data in its possession or control (including any copies thereof), unless and
            solely to the extent the foregoing conflicts with any applicable laws. At Retrain’s request, Client shall
            provide Retrain with a written certificate confirming that it has fully complied with the requirements of
            this clause.
          </span>
        </li>
        <li className="c9 li-bullet-2" id="h.qsh70q">
          <span className="c1">
            Termination of these Terms shall not relieve Client of its obligations to Retrain hereunder that arose prior
            to the effective date of termination, including all Client payment obligations that have accrued prior to
            the date of termination. In addition, payment obligations and provisions contained in Sections ‎‎3, ‎‎4,
            ‎‎‎5, ‎‎6.2 through ‎6.4, and ‎‎7 through ‎‎10 shall survive the expiration or termination of these Terms
            for any reason.{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={7}>
        <li className="c10 c18 li-bullet-15" id="h.4d34og8">
          <span className="c16 c2 c12 c19">DISCLAIMER OF WARRANTIES</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c4 li-bullet-16">
          <span className="c2">
            THE USER UNDERSTANDS AND AGREES THAT THE SERVICE PROVIDED BY RETRAIN IS PROVIDED ON AN “AS IS” AND “AS
            AVAILABLE” BASIS. TO THE FULLEST EXTENT PERMISSIBLE PURSUANT TO APPLICABLE LAW, RETRAIN AND ITS RELATED
            PARTIES DISCLAIM ALL WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED
            WARRANTIES OF TITLE, NON-INFRINGEMENT, ACCURACY, RELIABILITY, MERCHANTABILITY, AND FITNESS FOR A PARTICULAR
            PURPOSE.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-16">
          <span className="c2">RETRAIN AND ITS RELATED PARTIES DO NOT WARRANT: (</span>
          <span className="c2 c5">A</span>
          <span className="c2">
            ) THAT THE SERVICE AND ANY RELATED SERVICES PROVIDED WILL MEET CLIENT OR USER REQUIREMENTS OR EXPECTATIONS;
            (
          </span>
          <span className="c2 c5">B</span>
          <span className="c2">) THAT THE USE OF THE SERVICE WILL BE UNINTERRUPTED; </span>
          <span className="c2 c5">(C)</span>
          <span className="c2">&nbsp;THAT DEFECTS, IF ANY, WILL BE CORRECTED; (</span>
          <span className="c2 c5">D</span>
          <span className="c2">
            ) THAT THE DATA USED TO TRAIN THE RETRAIN PLATFORM WILL (i) MEET ANY DIVERSITY EXPECTATIONS, (ii) BE
            COMPLETELY FREE FROM ANY BIASES OR DISCRIMINATION, OR (iii) BE PERIODICALLY REVIEWED AND UPDATED.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-17">
          <span className="c2">
            The foregoing exclusions and disclaimers are an essential part of these Terms and formed the basis for
            determining the price charged for the Service and any related services.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-18" id="h.2s8eyo1">
          <span className="c2">
            Any and all warranties shall be void as to the Services damaged or rendered unserviceable by (1) the acts or
            omissions of non-Retrain personnel in connection with the use of the Service; (2) misuse, theft, vandalism,
            or other peril; and (3) moving, relocation, alterations or additions not authorized by Retrain in advance
            and in writing.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={8}>
        <li className="c10 c18 li-bullet-0">
          <span className="c2 c12">INDEMNIFICATION</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c4 li-bullet-19" id="h.17dp8vu">
          <span className="c2">Subject to the provisions of Sections ‎7 (</span>
          <span className="c2 c25">Disclaimer of Warranties</span>
          <span className="c2">) and ‎‎9 (</span>
          <span className="c2 c25">Limitation of Liability</span>
          <span className="c2">
            ), Retrain shall defend, indemnify and hold harmless Client and its officers, directors, agents, employees
            and assigns, from and against any and all claims, suits, proceedings, losses, damages, liabilities, costs
            and expenses (including, without limitation, reasonable attorneys’ fees) (collectively, “
          </span>
          <span className="c2 c12">Losses</span>
          <span className="c1">
            ”) suffered or incurred by them in connection with a third party claim arising out of any actual or
            threatened claim that the Service infringes upon or misappropriates any copyright, patent, trademark, trade
            secret, or other proprietary rights of any third party. Retrain shall have no obligation to indemnify Client
            to the extent the alleged infringement arises out of (i) the use of the Service in combination by Client
            with other data products, processes or materials not provided by Retrain and such infringement would not
            have occurred but for Client’s combination; or (ii) the use of any User Content. Should the Service as used
            by Client become, or in Retrain’s opinion be likely to become, the subject of an infringement claim, Retrain
            shall at its option and sole expense either: (x) procure for Client the right to continue to use the Service
            as contemplated hereunder, or (y) modify the Service to eliminate any such claim that might result from its
            use hereunder while maintaining all material functionality of the Service or (z)&nbsp;replace the Service
            with an equally suitable, compatible and functionally equivalent non-infringing application at no additional
            charge to Client. If none of these options is reasonably available to Retrain, then these Terms may be
            terminated at the option of either Party hereto without further obligation or liability on the part of
            either Party hereto, except that Retrain agrees to promptly refund to Client the Fees received by Retrain
            from Client during the three (3)-month period immediately preceding the date of termination.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-17">
          <span className="c1">
            Client shall defend, indemnify and hold harmless Retrain, its subsidiaries, affiliates, officers, directors,
            agents, employees and assigns, from and against any and all Losses suffered or incurred by them in
            connection with a third party claim arising out of (i) User’s illegitimate use of the Service (except to the
            extent that Retrain is responsible for such Losses under Section ‎‎8.1) including any breach of these Terms;
            or (ii) the infringement of any intellectual property rights of Retrain or any third party.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-20">
          <span className="c1">
            The obligations under the foregoing indemnities are subject to the condition that the Party seeking
            indemnification give the other: (1) prompt written notice of any claim or action for which indemnity is
            sought; (2) complete control of the defense and settlement thereof by the indemnifying Party; and (3)
            cooperation of the other Party in such defense.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={9}>
        <li className="c10 c18 li-bullet-0" id="h.3rdcrjn">
          <span className="c16 c2 c12 c19">LIMITATIONS OF LIABILITY</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c4 li-bullet-16">
          <span className="c2">
            NOTWITHSTANDING ANYTHING TO THE CONTRARY CONTAINED IN THESE TERMS, NEITHER PARTY SHALL BE LIABLE TO THE
            OTHER FOR ANY INDIRECT, INCIDENTAL, SPECIAL, OR CONSEQUENTIAL DAMAGES (INCLUDING LOST PROFITS) THAT RESULT
            FROM OR ARE RELATED TO THESE TERMS, EVEN IF IT HAS BEEN INFORMED OF THE POSSIBILITY OF SUCH DAMAGES. IN ANY
            EVENT, RETRAIN’S AGGREGATE LIABILITY TO CLIENT OR ANY USER FOR DAMAGES SHALL NOT EXCEED THE AMOUNTS US
          </span>
          <span className="c11">D </span>
          <span className="c1">$1,000.</span>
        </li>
        <li className="c4 li-bullet-21">
          <span className="c1">
            EACH PARTY UNDERSTANDS AND AGREES THAT THE FOREGOING LIABILITY LIMITATIONS ARE ESSENTIAL ELEMENTS OF THESE
            TERMS, SUCH LIMITATIONS SHALL SURVIVE FAILURE OF THEIR ESSENTIAL PURPOSE, AND THAT IN THE ABSENCE OF SUCH
            LIMITATIONS THE MATERIAL AND ECONOMIC TERMS OF THESE TERMS WOULD BE SUBSTANTIALLY DIFFERENT.
          </span>
        </li>
        <li className="c4 li-bullet-16" id="h.26in1rg">
          <span className="c1">
            Client agrees that the performance of the Service and Client’s access to, and use of, the Service may be
            affected adversely by the Internet connection because of (among other things) inadequate bandwidth available
            to Client, the type of connection, inadequate speed, lack of continuity of Internet service, and the number
            of users accessing the Internet at any given time over the same connection. Retrain is not responsible for
            performance of the Service due to events beyond the reasonable control of Retrain.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-0" start={10}>
        <li className="c10 c18 li-bullet-15" id="h.lnxbz9">
          <span className="c16 c2 c12 c19">MISCELLANEOUS </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_1-1 start" start={1}>
        <li className="c4 li-bullet-4">
          <span className="c1">
            Non-enforcement by Retrain of any term or condition of these Terms shall not constitute a waiver. A waiver
            by Retrain of compliance with any term or condition under these Terms shall not constitute a waiver of such
            term or condition at any other time or a waiver in the future of any other term or condition of these Terms.
          </span>
        </li>
        <li className="c4 li-bullet-17" id="h.35nkun2">
          <span className="c0">Severability</span>
          <span className="c1">
            . If any provision hereof is held invalid or unenforceable by a court of competent jurisdiction, such
            invalidity shall not affect the validity or operation of any other provision and such invalid provision
            shall be deemed severed from these Terms.
          </span>
        </li>
        <li className="c4 li-bullet-4" id="h.1ksv4uv">
          <span className="c0">Notices</span>
          <span className="c1">
            . Any formal notice, consent or other communication in connection with these Terms shall be in writing and
            shall be considered to have been delivered and in effect upon the earlier of actual receipt or: (a) an email
            followed by a written or electronic confirmation; (b) two (2) days after posting when sent via an express
            commercial courier; (c) five (5) days after posting when sent via certified mail; or (d) one (1) day after
            posting through the “in-service” notifications. You consent that all communications that Retrain provide to
            you electronically satisfy any legal requirement that such communications be in writing or be delivered in a
            particular manner and you agree to keep your Account contact information, including email address updated at
            all times.
          </span>
        </li>
        <li className="c4 li-bullet-19" id="h.44sinio">
          <span className="c0">Jurisdiction and Disputes</span>
          <span className="c1">
            . These Terms shall be governed by the laws of the State of Delaware without regard to its conflict of law
            principles. All disputes hereunder shall be resolved, exclusively, in the applicable courts of Delaware,
            United States. The Parties consent to the jurisdiction of such courts and waive any jurisdictional or venue
            defenses otherwise available.
          </span>
        </li>
        <li className="c4 li-bullet-17">
          <span className="c0">No Agency</span>
          <span className="c1">
            . The relationship of the Parties established by these Terms is solely that of independent contractors, and
            nothing contained in these Terms shall be construed to: (i) give any Party the power to direct and control
            the day-to-day activities of the other; or (ii) constitute such Parties as partners, joint ventures, co-owns
            or otherwise as participants in a joint or common undertaking; or (iii) make either Party an agent of the
            other for any purpose whatsoever. Neither Party nor its agents and employees are the representative of the
            other for any purpose, and neither has power or authority to act as agent or employee to represent, act for,
            bind, or otherwise create or assume any obligation on behalf of the other.
          </span>
        </li>
        <li className="c4 li-bullet-21">
          <span className="c0">Change in Law</span>
          <span className="c1">
            . If any law or regulation, including enactment of new state or federal legislation, amendments to existing
            laws and legislation, and or new or amended judicial orders, rules or decrees, creates adverse consequences
            to Retrain, according to its legal counsel, Retrain may perform any modification to these Terms or the
            Services to alleviate such adverse consequences.
          </span>
        </li>
        <li className="c4 li-bullet-18" id="h.2jxsxqh">
          <span className="c0">Assignment</span>
          <span className="c1">
            . The License granted under these Terms may not be assigned, sub-licensed, or otherwise transferred by the
            Client to any third-party except with prior written consent of Retrain, which consent shall not be
            unreasonably withheld. Retrain shall be entitled to transfer or assign the rights in and to the License
            under these Terms to any of its subsidiaries or affiliates.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-19">
          <span className="c0">Force</span>
          <span className="c0 c25">&nbsp;</span>
          <span className="c25 c11 c33">Majeure</span>
          <span className="c2">
            . Other than Client's obligation to pay the fees to Retrain, neither Party shall be liable to the other for
            any failure to meet its obligations if such failure is due to any cause beyond the non-performing Party's
            reasonable control ("
          </span>
          <span className="c2 c5">Force Majeure</span>
          <span className="c1">
            "). Force Majeure specifically includes, but is not limited to, any government action that would limit the
            ability for performance; fires; earthquakes, floods or other severe weather conditions or any other acts of
            God; quarantines; riots; strife; insurrection; civil disobedience; epidemics, armed conflict; terrorism or
            war, declared or not; or any impending threat of any of the foregoing, if such threat might reasonably be
            expected to cause injury to people or property.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-4">
          <span className="c1">
            These Terms and any exhibits and schedules attached hereto constitute the entire agreement between the
            Parties in connection with the subject matter hereof and supersede all prior and contemporaneous agreements,
            understandings, negotiations and discussions, whether oral or written, of the Parties, and there are no
            warranties, representations and/or agreements among the Parties in conjunction with the subject matter
            hereof except as set forth in these Terms.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-4">
          <span className="c1">
            USER ACKNOWLEDGES THAT IT: HAS READ THESE TERMS IN THEIR ENTIRETY, UNDERSTOOD THEM, AND AGREES TO BE BOUND
            BY THEM. USER FURTHER AGREES THAT THESE TERMS REPRESENT THE COMPLETE AND EXCLUSIVE STATEMENT OF THE RIGHTS
            AND OBLIGATIONS BETWEEN THE PARTIES, WHICH SUPERSEDES ALL PROPOSALS, ORAL OR WRITTEN, AND ALL OTHER
            COMMUNICATIONS BETWEEN THE PARTIES RELATING TO THE SUBJECT MATTER OF THESE TERMS. BY ACCEPTING THESE TERMS,
            USER ALSO CERTIFIES THAT IT IS IN COMPLIANCE WITH ALL LAWS AND REGULATIONS AS APPLIED TO HIM/HER.
          </span>
        </li>
      </ol>
    </div>
  );
};
