import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    background: 'linear-gradient(180deg, #407BFF 0%, #95A0FE 100%);',
  },

  sofiClientRoot: {
    backgroundColor: theme.palette.shades.white,
  },

  header: {
    backgroundColor: theme.palette.shades.white,
    padding: theme.spacing(3),
    position: 'sticky',
    minHeight: 70,
    top: 0,
    zIndex: 100,

    [theme.breakpoints.down('xs')]: {
      alignItems: 'center',
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(6.25),
      justifyContent: 'start',
    },
  },

  description: {
    backgroundColor: theme.palette.shades.white,

    '& p': {
      fontSize: 17,
    },

    [theme.breakpoints.down('xs')]: {
      borderRadius: '0px 0px 16px 16px',
      boxShadow: ' 0px 4px 8px rgba(206, 206, 206, 0.25)',
      padding: theme.spacing(5, 4),
      textAlign: 'center',
    },

    [theme.breakpoints.up('sm')]: {
      boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.08)',
      padding: theme.spacing(8, 8, 32, 19.5),
      borderRadius: '0 0 100% 0/ 0 0 52% 0',
      '& p': {
        display: 'inline',

        '&:last-child': {
          textTransform: 'lowercase',
        },
      },
    },
  },

  registration: {
    marginTop: theme.spacing(8),
  },

  sendAgain: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(1.5),
    textAlign: 'center',
    color: theme.extraOptions.newPallette.Grey,

    '& span': {
      textTransform: 'lowercase',
    },
  },
}));

export const useCommonStyles = makeStyles()((theme) => ({
  link: {
    borderBottom: `1px solid ${theme.extraOptions.newPallette.DarkGrey}`,
    cursor: 'pointer',
  },

  title: {
    [theme.breakpoints.down('xs')]: {
      marginBottom: theme.spacing(5),
    },
  },

  errorText: { fontSize: 12, fontWeight: 600, lineHeight: '18px' },

  otpError: {
    color: theme.extraOptions.newPallette.Red,
    visibility: 'hidden',
    height: 18,
    marginTop: theme.spacing(2),
  },

  card: {
    boxShadow: ' 0px 4px 8px rgba(206, 206, 206, 0.25)',
    borderRadius: 16,
    backgroundColor: theme.extraOptions.newPallette.White,

    [theme.breakpoints.down('xs')]: {
      margin: theme.spacing(5, 4),
      padding: theme.spacing(4),
    },

    [theme.breakpoints.up('sm')]: {
      boxShadow: '0px -2px 8px rgba(206, 206, 206, 0.39)',
      position: 'relative',
      maxWidth: 1004,
      width: '100%',
      margin: theme.spacing(-17.5, 'auto', 0),
      padding: theme.spacing(6, 15),
    },
  },

  // divider
  dividerContainer: {
    position: 'relative',
    margin: theme.spacing(8.5, 0, 3.5),
  },

  dividerText: {
    backgroundColor: theme.palette.shades.white,
    maxWidth: 156,
    width: '100%',
    margin: 'auto',
    textAlign: 'center',
    position: 'relative',
    marginTop: theme.spacing(-2.5),
  },

  divider: {
    backgroundColor: theme.extraOptions.newPallette.LightGrey,
  },

  // buttons
  submitButton: {
    [theme.breakpoints.down('xs')]: {
      marginTop: theme.spacing(10),
      marginBottom: theme.spacing(28),
      width: '100%',
    },

    [theme.breakpoints.up('sm')]: {
      margin: theme.spacing(25, 0, 0, 'auto'),
      display: 'block',
    },

    '&.MuiButton-root:hover.Mui-disabled': {
      backgroundColor: theme.extraOptions.newPallette.LightGrey,
    },
  },

  verifySofiClientOtp: {
    width: '100%',
    padding: theme.spacing(3.5, 0),
    marginTop: theme.spacing(5),
    textTransform: 'none',
  },

  disabled: {
    pointerEvents: 'none',
  },

  visible: {
    visibility: 'visible',
  },
}));
