import { createConfiguration, TenantApi, TenantDto } from '@retrain-ai/workspace-selector-api';

const config = createConfiguration();
const tenantApi = new TenantApi(config);

const fakeTenants: TenantDto[] = [
  { id: '123', url: '/sign-in', name: 'demo' },
  { id: '345', url: '/sign-in', name: 'bor' },
];

export interface TenantManager {
  validateWorkspace: (workspace: string) => Promise<TenantDto | undefined>;
}

export function useFakeTenantManager(): TenantManager {
  const validateWorkspace = (workspace: string): Promise<TenantDto | undefined> => {
    const tenant = fakeTenants.find((tenant) => tenant.name === workspace.toLocaleLowerCase());
    return Promise.resolve(tenant);
  };

  return {
    validateWorkspace,
  };
}

export function useTenantManager(): TenantManager {
  const validateWorkspace = async (tenant: string): Promise<TenantDto | undefined> => {
    try {
      const tenantDto = await tenantApi.tenantControllerFindTenant(tenant);
      return tenantDto;
    } catch (e) {
      return undefined;
    }
  };

  return {
    validateWorkspace,
  };
}
