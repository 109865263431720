export const checkMobileDevice = (userAgent: string) => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini/i.test(
      userAgent,
    )
  ) {
    return true;
  }
  return false;
};
