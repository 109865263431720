import { useStyles } from './privacy-policy.style';

export const PrivacyPolicy = () => {
  const { classes: s } = useStyles();

  return (
    <div className={s.content} dir="ltr">
      <h1 className="c13">
        <span className="c17 c32">Retrain</span>
      </h1>
      <h1 className="c13">
        <span className="c32 c17">Privacy Policy </span>
      </h1>

      <p className="c27">
        <span className="c8">Thank you (“</span>
        <span className="c17">you</span>
        <span className="c8">”, “</span>
        <span className="c17">your</span>
        <span className="c8">”, or “</span>
        <span className="c17">User</span>
        <span className="c8">”) for accessing </span>
        <span className="c10">
          <a
            className="c16"
            href="https://www.google.com/url?q=https://www.app.retrain.ai/&amp;sa=D&amp;source=editors&amp;ust=1661352341711215&amp;usg=AOvVaw0OTAi2_bndR1suOEl8KXkV"
          >
            https://www.app.retrain.ai/
          </a>
        </span>
        <span className="c8">&nbsp;(the “</span>
        <span className="c17">Retrain Platform</span>
        <span className="c8">
          ”), and/or any of the products and/or services (which together with the Retrain Platform shall be referred to
          herein as the "
        </span>
        <span className="c17">Service</span>
        <span className="c8">
          ") offered by Retrain AI Inc., a company incorporated under the laws of Delaware and/or its affiliates (“
        </span>
        <span className="c17">we</span>
        <span className="c8">”, “</span>
        <span className="c17">us</span>
        <span className="c8">”, “</span>
        <span className="c17">our</span>
        <span className="c8">”, or “</span>
        <span className="c17">Retrain</span>
        <span className="c2">
          ”). This Privacy Policy sets out the basis on which any personal information we collect from you, or that you
          (or Retrain’s Customer) provide to us, will be processed by us, through the use of any of the Services made
          available by us. We encourage you to read this Privacy Policy carefully and use it to make informed decisions.
        </span>
      </p>

      <p className="c14">
        <span className="c1">
          PLEASE READ THE FOLLOWING TERMS CAREFULLY TO UNDERSTAND HOW WE WILL COLLECT, USE, AND MAINTAIN YOUR PERSONAL
          INFORMATION.{' '}
        </span>
      </p>
      <ol className="c15 lst-kix_list_4-0 start" start={1}>
        <li className="c12 li-bullet-0">
          <span className="c1">INFORMATION COLLECTED</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1" id="h.gjdgxs">
          <span className="c2">
            Retrain collects information that identifies individuals or that may, with reasonable effort, identify
            individuals, including (the “
          </span>
          <span className="c1">Personal Information</span>
          <span className="c2">"):</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">Your name; professional experience;</span>
          <span className="c8 c28">&nbsp;curriculum vitae</span>
          <span className="c2">
            ; job title and position (including past roles); seniority; and the name of your supervisor.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Your contact details; postal address; telephone numbers (this may include, for example, your mobile number,
            office number, etc.) and business or private e-mails.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Your online browsing activities through the Retrain Platform (for example, device, software or hardware that
            may identify them, such as online identifiers, device unique identifiers (e.g. UDID, MAC address), IP
            address and geolocation).
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Your password(s) and usernames. Also, if you choose to set up an account when using the Retrain Platform by
            logging through your Facebook or other social network account, we also may receive personal information
            about you from the social network.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Your interests, preferences, feedback and survey responses.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Your correspondence and communications with Retrain.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            other publicly available personal data, including any which you have shared via other public platforms (for
            example, user ID for LinkedIn or any other social network through which you choose to register, and other
            contact details and any other information you choose to provide).
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1" start={2}>
        <li className="c9 li-bullet-1">
          <span className="c3">Non-Personal Information</span>
          <span className="c2">
            . Retrain also collects certain&nbsp;unidentified, non-personal&nbsp;information that relates to your online
            browsing activities through your use of the Retrain Platform (the “
          </span>
          <span className="c1">Non-Personal Information</span>
          <span className="c2">
            ”). Non-Personal Information is non-identifiable information that, when taken alone, cannot be used to
            identify you. As such, we are not aware of the identity of the user from which the Non-Personal Information
            was collected. We also collect aggregate user data regarding your use of our Services. We may anonymize or
            de-identify the information collected through the Service or via other means so that the information cannot,
            on its own, personally identify you. Our use and disclosure of such aggregated or de-identified information
            is not subject to any restrictions under this Privacy Policy, and we may disclose it to others without
            limitation and for any purpose. For the avoidance of doubt, if we combine Personal Information with
            Non-personal Information (e.g., analytics data), the combined information will be treated as Personal
            Information as long as it remains combined.{' '}
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={2}>
        <li className="c12 li-bullet-3" id="h.30j0zll">
          <span className="c1">HOW WE COLLECT YOUR PERSONAL INFORMATION</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain may collect Personal Information and/or Non-Personal Information (collectively, “
          </span>
          <span className="c1">Information</span>
          <span className="c2">”) in the following ways:</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c3">Information You Provide to Retrain</span>
          <span className="c2">. </span>
        </li>
      </ol>
      <p className="c20">
        <span className="c2">
          Retrain collects Information that you provide it, for example, when you use the Retrain Platform by accessing
          the account that was set up for you by your employer (i.e., Retrain’s Customer) or when you use the Service;
          when you answer questionnaires; send request customer support; or communicate with us for other purposes.{' '}
        </span>
      </p>

      <p className="c20">
        <span className="c2">
          In addition, when you use the Retrain Platform, you can post and upload messages, photos, comments, and other
          content (the “
        </span>
        <span className="c1">User Content</span>
        <span className="c2">
          ”). We will use the User Content to enable you to publish the content to your account via the Retrain
          Platform, for monitoring, management, analysis, segmenting, improving the experience of using the Retrain
          Platform and any other legitimate use as per the license you granted to us under the{' '}
        </span>
        <span className="c2 c11">Service Terms of Use</span>
        <span className="c2">.</span>
      </p>

      <p className="c20">
        <span className="c2">
          Please be aware that the Information you choose to provide, may reveal, or identify, information that is not
          expressly stated (for example, your name or photo may reveal your gender). Accordingly, you should carefully
          consider which information you wish to share about yourself when publishing your User Content.{' '}
        </span>
      </p>

      <ol className="c15 lst-kix_list_4-2" start={2}>
        <li className="c6 li-bullet-2">
          <span className="c3">Information Provided to Retrain by Your Employer</span>
          <span className="c2">. </span>
        </li>
      </ol>
      <p className="c20">
        <span className="c2">Retrain collects Information that was provided to it by the User’s employer (the “</span>
        <span className="c1">Retrain’s Customer</span>
        <span className="c2">
          ”) when setting up your account on your behalf to enable the functionality and features of the Service. This
          usually means that your employer, Retrain’s Customer, will provide Retrain with your name, email address,
          current position and past roles, seniority, the name of your supervisor, etc.
        </span>
      </p>

      <p className="c20">
        <span className="c2">Retrain’s Customer, please note that it is </span>
        <span className="c3">your responsibility</span>
        <span className="c2">
          &nbsp;to ensure that you have the right to provide us with Information regarding any User to which you wish
          granting access to the Service.{' '}
        </span>
      </p>

      <ol className="c15 lst-kix_list_4-1" start={2}>
        <li className="c9 li-bullet-1">
          <span className="c3">Information Collected Automatically Upon the Use in the Retrain Services</span>
          <span className="c2">
            . When you access or use the Retrain Services, we may automatically collect Information about you,
            including:{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c3">Usage Information</span>
          <span className="c2">
            . Retrain monitors Users’ activity in connection with the Retrain Services and may collect log information
            about you when you access and use the Retrain Services including your IP address, time of access, browser
            type and language, Internet Service Provider ("ISP"), information about the applications and features you
            use, , the content you access and any actions taken in connection with the access and use of your content in
            the Retrain Services.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c3">Device Information</span>
          <span className="c2">
            . If you access the Retrain Services from a mobile device, Retrain may collect information about the device,
            including the hardware model, operating system and version, unique device identifiers, mobile network
            information (as allowed by the mobile network) or platform information (as allowed by the specific platform
            type).{' '}
          </span>
        </li>
        <li className="c6 li-bullet-2" id="h.1fob9te">
          <span className="c3">Cookies</span>
          <span className="c2">. </span>
        </li>
      </ol>
      <p className="c20">
        <span className="c2">
          Like most websites and online platforms, Retrain’s Service uses “cookies” to collect Information. “Cookies”
          are small text files containing strings of letters and numbers that are downloaded onto your device (such as,
          computer, tablet or smartphone) when you use the Retrain Platform. These files allow to store Information
          about you. Some “cookies” will expire when you exit the Retrain Platform, and others will be saved in your
          device’s memory.{' '}
        </span>
      </p>
      <p className="c20">
        <span className="c2">
          If you want to disable or change your “cookies” settings, you will have to access your device’s browser
          settings. Please note that if you disable all cookies, some (or all) of the features and functionality of the
          Service may not be available to you.{' '}
        </span>
      </p>
      <ol className="c15 lst-kix_list_4-2" start={4}>
        <li className="c6 li-bullet-2" id="h.3znysh7">
          <span className="c3">Information Collected by Other Tracking Technologies</span>
          <span className="c2">
            . Other than “cookies”, when you use our Service, you consent to our use (and authorize third parties to
            use) of various technologies to collect Information, which may include web beacons (also known as "tracking
            pixels"), events and other technologies (collectively, “
          </span>
          <span className="c1">Tracking Technologies</span>
          <span className="c2">
            ”). Such Tracking Technologies allow us to automatically collect Information about you, your device and your
            online behavior, in order to enhance your navigation on the Retrain Platform, improve our Services’
            performance, perform analytics and customize your experience. In addition, we may merge data we have with
            Information collected through these Tracking Technologies and data we may obtain from other sources and, as
            a result, such combined data may constitute Personal Information.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={3}>
        <li className="c12 li-bullet-4" id="h.2et92p0">
          <span className="c1">USE OF INFORMATION</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain may use the Information collected for the limited purpose of providing the Retrain Service and
            related functionality and services, or as otherwise specifically described in this Privacy Policy and as
            permitted by applicable laws. Such limited purposes include circumstances where it is necessary for Retrain
            to provide or fulfill Services requested by, or for, you, or where you have given Retrain your express
            consent. The Information may be used to perform a variety of legitimate purposes, including to:
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">Provide, operate, maintain, improve, promote and audit the Retrain Services.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Enable you to access and use the Retrain Service.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Send you transactional messages, technical notices, updates, security alerts and support and administrative
            messages.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Identify you, so that we can provide and deliver the services and features you request, process and complete
            transactions, and send you related information, including purchase confirmations and invoices.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Respond to your comments, questions, and requests and provide customer service and support.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Communicate with you about services, features, surveys, newsletters, offers, promotions, contests and
            events, and provide other news or information about Retrain and our select partners, including responding to
            your comments, questions, and requests.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Monitor and analyze trends, usage, and activities in connection with the Retrain Services for research,
            marketing or advertising purposes.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Personalize and improve the Retrain Services, and provide content, features, and/or advertisements that
            match your interests and preferences or otherwise customize your experience on the Retrain Services.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2 c11">
            If you are a prospective candidate applying for a job at Retrain, or seeking a new job elsewhere, we may
            collect professional information about you such as your CV, information about your last employer(s), and
            other information you may provide for managing your application process.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Investigate and prevent fraudulent transactions, unauthorized access to the Service, and other illegal
            activities.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">For managing insurance claims.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Cybersecurity needs, fraud detection and misuse of Retrain’s Services.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">User experience enhancements and improvements.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Link or combine the Information with other information we receive from third parties to help understand your
            needs and provide you with better service.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Internal record keeping.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">For crime and fraud prevention, detection and related purposes.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Where it has a legal right or duty - to use or disclose your information (for example in relation to an
            investigation by a public authority or in a legal dispute).
          </span>
        </li>
      </ol>

      <p className="c25">
        <span className="c2">
          Please note that where we rely on your consent to process any of your Personal Information, you have the right
          to withdraw or decline consent at any time. Where we rely on our legitimate interests to process your Personal
          Information, you have the right to object. If you have any questions about or need further information
          concerning the legal basis on which we collect and use your Personal Information, please contact us through
          the contact details available below.
        </span>
      </p>
      <ol className="c15 lst-kix_list_4-1" start={2}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain may send you push notifications from time-to-time through the Retrain Platform in order to update
            you about events or activities related to the Service. If you no longer wish to receive these types of
            communications, you may turn them off. To ensure you receive proper notifications, we collect certain
            information about your device such as operating system and user identification information.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-1" start={3}>
        <li className="c9 li-bullet-5">
          <span className="c3">Marketing (Promotional communications)</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Retrain may use your email address to send you electronic mail for marketing purposes and update you about
            promotional offers, products and services which we think may be of interest to you as an individual (the "
          </span>
          <span className="c1">Newsletters</span>
          <span className="c2">").</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            You are not obligated by law to receive our Newsletters and you have the right to opt out of receiving such
            communications, any time, by:{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-3 start" start={1}>
        <li className="c4 li-bullet-6">
          <span className="c2">following the unsubscribe instructions included in each Newsletter; or </span>
        </li>
        <li className="c4 li-bullet-7" id="h.tyjcwt">
          <span className="c2">contacting Retrain via </span>
          <span className="c5">
            <a className="c16" href="mailto:privacy@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">. </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2" start={3}>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Please note that regardless of your election, Retrain may continue sending you notices (a) to update you on
            revisions made to this Privacy Policy or our Terms of Service; and (b) related to the ongoing Service, for
            example, promotions regarding products and services used by you, notices concerning your accounts and any
            additional operational notices as required.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-1" start={4}>
        <li className="c9 li-bullet-1" id="h.3dy6vkm">
          <span className="c3">Sharing Your Information With Third Parties.</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">
            We may use and share your Information with certain third parties that help us operate our Service, (e.g.,
            deliver customer support, monitor and analyze the performance of our Services, provide marketing support,
            etc.), such as newsletter distribution, cloud providers and other service providers. When Retrain uses such
            third parties, it restricts them from using or disclosing the Information, except as required to perform the
            services on behalf of Retrain or to comply with legal requirements. Specifically, we do not permit such
            third parties to use any Personal Information we share with them for their own marketing purposes or for any
            purpose other than in connection with the services they provide to us. Third parties with which we may share
            your Information will be required to meet our standards on processing information and security. Personal
            Information we provide them will only be provided in connection with the performance of their function.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Retrain may share your data with:</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-3 start" start={1}>
        <li className="c4 li-bullet-7">
          <span className="c2 c11">
            Its partners and clients who seek information on potential job candidates that may be suitable for their
            needs. Our clients may be your current employer or third parties that use the Service to be able to get to
            know you and your professional background. If our client is your current employer, your activity within the
            Service may also be monitored, processed and analyzed by such client. When we share your information with
            clients that are seeking potential candidates, we restrict them from using or disclosing the Information,
            except as authorized by the limited License granted to them under the{' '}
          </span>
          <span className="c2 c11">Service Terms of Use</span>
          <sup>
            <a href="#cmnt1" id="cmnt_ref1">
              [a]
            </a>
          </sup>
          <span className="c2">.</span>
          <span className="c2 c11">&nbsp;</span>
        </li>
        <li className="c4 li-bullet-6">
          <span className="c2">
            governmental bodies, regulators, law enforcement agencies, courts/tribunals and insurers where we are
            required to do so (a) to comply with our legal obligations; (b) to exercise our legal rights (for example in
            court cases); (c) for the prevention, detection, investigation of crime or prosecution of offenders,
            situations involving potential threats to the physical safety of any person, violations of our policies, or
            as otherwise required to comply with our legal obligations; and (d) for the protection of our employees and
            customers.
          </span>
        </li>
        <li className="c4 li-bullet-6">
          <span className="c2">
            If Retrain becomes involved in a merger, acquisition, or any form of sale of some, or all, of its assets, it
            may transfer all the Information that was collected from users in connection with such a transaction.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1" start={5}>
        <li className="c9 li-bullet-1" id="h.1t3h5sf">
          <span className="c3">International Transfer of Information. </span>
          <span className="c2">
            Certain aspects of the Service are performed by Retrain’s affiliates, which is why it might be necessary for
            us to share your Information outside of your country of residency, with parties located in other countries
            in which we do business. If we shall transfer your Information outside of your country of residency, we will
            take all reasonable measures to ensure that such transfer will be compliant with data protection law.
            Retrain’s standard practice is to use the ‘standard data protection clauses’ which have been approved by the
            European Commission for such transfers. Those clauses can be accessed{' '}
          </span>
          <span className="c5">
            <a
              className="c16"
              href="https://www.google.com/url?q=https://ec.europa.eu/info/law/law-topic/data-protection_en&amp;sa=D&amp;source=editors&amp;ust=1661352341721641&amp;usg=AOvVaw1kI0cXZeVk_LVt8foo_88a"
            >
              here
            </a>
          </span>
          <span className="c2">.</span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-1" start={6}>
        <li className="c9 li-bullet-1">
          <span className="c3">Advertising and Analytics Services.</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">Retrain may use standard analytics tools such as </span>
          <span className="c5">
            <a
              className="c16"
              href="https://www.google.com/url?q=https://analytics.google.com/analytics/web/provision/%23/provision&amp;sa=D&amp;source=editors&amp;ust=1661352341722695&amp;usg=AOvVaw0jW3fgTZRjhPKhIlchxqAT"
            >
              Google Analytics
            </a>
          </span>
          <span className="c2">
            , and may provide you with opportunities to connect with third-party websites, applications or services such
            as application tracking systems. This Privacy Policy does not apply to your use of such third-parties’
            websites, applications and services, and Retrain is not responsible for how those third parties collect, use
            and disclose your Information and content, because these tools maintain their own privacy practices in
            accordance with their own privacy policies to provide their service. For example, if you wish to know how
            Google uses data when you use our Service, you can visit{' '}
          </span>
          <span className="c5">
            <a
              className="c16"
              href="https://www.google.com/url?q=https://policies.google.com/technologies/partner-sites&amp;sa=D&amp;source=editors&amp;ust=1661352341723357&amp;usg=AOvVaw2HJtzOWPMbtKbcqGFzkQOy"
            >
              here
            </a>
          </span>
          <span className="c2">.</span>
        </li>
      </ol>

      <p className="c20">
        <span className="c2">
          Retrain strongly encourages its users to review the privacy policies of those third parties before connecting
          to or using their applications or services to learn more about their information and privacy practices.
        </span>
      </p>

      <ol className="c15 lst-kix_list_4-2" start={2}>
        <li className="c6 li-bullet-2">
          <span className="c2">If you </span>
          <span className="c3">do not</span>
          <span className="c2">
            &nbsp;want us to share your Personal Information for marketing and advertising purposes, you may opt-out in
            accordance with provisions below section below or contact us using the details provided below. Please note
            that even if you unsubscribe, Retrain may still use and share your Personal Information with third parties
            for non-marketing purposes (for example to fulfill your requests and orders, communicate with you and
            respond to your inquiries, etc.). In such cases, the entities with whom we share your Information are
            authorized to use your Personal Information only as necessary to provide these non-marketing services.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">How To Opt-Out?</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-3 start" start={1}>
        <li className="c4 li-bullet-6">
          <span className="c3">Emails</span>
          <span className="c2">
            . If you no longer wish to receive emails from us, you may opt out of receiving them at any time, by
            following the ‘unsubscribe’ process detailed at the bottom of any e-mail communication we send, or by
            sending us an email with your request to{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">. &nbsp; &nbsp; </span>
        </li>
      </ol>
      <p className="c34 c36">
        <span className="c2">
          Please note that even if you opt out of receiving marketing communications, we may continue to send you
          transactional communications about your subscription or orders by postal mail.
        </span>
      </p>

      <ol className="c15 lst-kix_list_4-3" start={2}>
        <li className="c4 li-bullet-6">
          <span className="c3">Cookies</span>
          <span className="c2">
            . You can choose to have your device warn you each time a persistent or session cookie is being sent, or you
            can choose to turn off such cookies through your browser settings.{' '}
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-1" start={7}>
        <li className="c9 li-bullet-1">
          <span className="c3">Our Cloud Service Provider</span>
          <span className="c2">
            . The server(s) on which our Service is hosted are located within the European Union, and are managed by
            third parties, including,{' '}
          </span>
          <span className="c1">Google Cloud Platform </span>
          <span className="c2">(</span>
          <span className="c1">GCP</span>
          <span className="c2">
            ), whose mailing address is 1600 Amphitheatre PkwyMountain ViewCA 94043, ATTN: Google Legal Investigations
            Support. You can find more details on GCP's Privacy Policy{' '}
          </span>
          <span className="c5">
            <a
              className="c16"
              href="https://www.google.com/url?q=https://policies.google.com/privacy&amp;sa=D&amp;source=editors&amp;ust=1661352341725277&amp;usg=AOvVaw1rKTthYjrOIIWBCgJhS5Op"
            >
              here
            </a>
          </span>
          <span className="c2">. We have configured our data to be stored in the Netherlands.</span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-1" start={8}>
        <li className="c9 li-bullet-5">
          <span className="c2">
            We reserve the right to disclose your Information as required by law and when we believe that disclosure is
            necessary to protect our rights and/or comply with a judicial proceeding, or court order. Also, please note
            that we may disclose general, aggregated, non-personal and non-identifiable information about our users to
            potential business partners, investors, or the public.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-1" start={9}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            For other purposes about which we will provide you with prior notice as described in Section ‎12 (
          </span>
          <span className="c28 c8">Changes to This Policy</span>
          <span className="c2">).</span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={4}>
        <li className="c12 li-bullet-3" id="h.1t3h5sf-1">
          <span className="c1">SPECIFIC PROVISION FOR CALIFORNIA RESIDENTS</span>
        </li>
      </ol>
      <p className="c33">
        <span className="c8">
          This&nbsp;Section ‎‎4 applies to you only if you are a resident of the State of California, United States.
          Retrain adopted the following provisions to comply with the California Consumer Privacy Act (“
        </span>
        <span className="c17">CCPA</span>
        <span className="c2">
          ”) and any terms defined in the CCPA have the same meaning when used in this Section ‎‎4.
        </span>
      </p>
      <ol className="c15 lst-kix_list_4-1" start={10}>
        <li className="c9 li-bullet-1" id="h.4d34og8">
          <span className="c2">
            The following categories reflect the type of personal information which Retrain has collected within the{' '}
          </span>
          <span className="c3">last twelve (12) months</span>
          <span className="c2">:</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Identifiers and Personal information as listed in the California Customer Records statute (Cal. Civ. Code §
            1798.80(e)), for example, first names, surnames, telephone numbers, address, online Identifier, Internet
            Protocol address, business or private email addresses, account usernames, as well as Personal Information as
            such term is defined in this Privacy Policy.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Electronic network activity information, including, but not limited to, browsing history and any additional
            information related to your interaction with our Service.{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1" start={11}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain has obtained such information as set forth in Section ‎4.1 above and Personal Information as
            provided under Section ‎1.1 above (for the purpose of this Section ‎4, the “
          </span>
          <span className="c1">Personal Data</span>
          <span className="c2">”). </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain may use the Personal Data it collects or receives for its legitimate interests (as set out in
            Section ‎3 above), which Retrain believes are not overridden by your fundamental rights. Retrain may also
            disclose such Personal Data to third parties for its legitimate purposes as described in Section ‎3 above.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">In the preceding twelve (12) months, Retrain </span>
          <span className="c17 c24">has</span>
          <span className="c2">&nbsp;disclosed the following Personal Data for our business purposes:</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">Identifiers (including your name, email address, profession).</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Personal Data's categories as listed in the California Customer Records statute.</span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">Inferences.</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1" start={14}>
        <li className="c9 li-bullet-1">
          <span className="c2">In the preceding twelve (12) months, Retrain has </span>
          <span className="c24 c17">not</span>
          <span className="c2">&nbsp;sold any of your Personal Data.</span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c3">Your rights as a California Resident</span>
          <span className="c2">
            . You are entitled to request the following specific rights under the CCPA, solely with respect to Personal
            Data related to you:
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Request to know (a) what categories and specific components of Personal Data we collect about you and from
            which sources; (b) categories of Personal Data that we disclosed for the purpose described in Section ‎3
            above, and the categories of third parties with whom we have shared any particular category of your Personal
            Data. If we disclose any of your Personal Data to a third-party, we will provide you, after authenticating
            your identity, with a list that will identify the specific category of your Personal Data which was
            disclosed.{' '}
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Request that we delete any Personal Data we collect about you. After authenticating your identity, we will
            delete (and direct our service providers to delete) any Personal Data related to you from our records,
            unless an exception applies. Please note that if we need to delete any Personal Data related to you
            following your request, it can take time until we completely delete residual copies of Personal Data from
            our servers and backup systems.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Instruct us not to sell any Personal Data related to you that was collected by us.{' '}
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            You have the right not to be discriminated against by Retrain, for exercising your rights under the CCPA.{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1" start={16}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            If you have any concerns about how we process Personal Data related to you, or if you wish to withdraw your
            consent, for any reason, kindly let us know by sending an email to{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">
            . Please note that exercising this right will not affect the lawfulness of any previous processing
            activities based on consent that was lawfully obtained before its withdrawal. Also, please note that Retrain
            shall not charge you for requesting to exercise any of the rights set forth in this Section ‎4.{' '}
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-0" start={5}>
        <li className="c19 c31 li-bullet-0" id="h.2s8eyo1">
          <span className="c1">SPECIFIC PROVISIONS FOR EU-RESIDENTS</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            This&nbsp;Section ‎5 applies to you only if you are a resident of the European Economic Area (EEA). Retrain
            adopted the following provisions, to comply with the EU 2016/679 Directive General Data Protection
            Regulation (“
          </span>
          <span className="c1">GDPR</span>
          <span className="c2">
            ”), pursuant to which Retrain will be considered as a "Data Controller" with respect to our use of Personal
            Information of residents of the European Union.
          </span>
        </li>
        <li className="c9 li-bullet-8">
          <span className="c3">Legal Basis</span>
          <span className="c2">
            . Retrain bases its processing activities of any Personal Information related to you as “Data Controllers”
            based on the following lawful grounds:
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-2 start" start={1}>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Retrain relies, primarily, on your consent to the terms of this Privacy Policy and the terms set forth under
            our{' '}
          </span>
          <span className="c2 c11">Service Terms of Use</span>
          <span className="c2">
            , as a legal basis for processing any Personal Information related to you or communicating any other
            promotional material.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            Retrain may collect and use your Personal Information when it is necessary for one of the legitimate uses
            set out in Section ‎3 above, which we believe are not overridden by your fundamental rights.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            We may process your Personal Information to comply with a legal obligation and to protect our users' vital
            interests.
          </span>
        </li>
        <li className="c6 li-bullet-2">
          <span className="c2">
            If, at any time, you wish to exercise your rights in accordance with the provisions provided by law
            (including as provided under this Section ‎5 of this Privacy Policy) you may send us an email to{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">&nbsp;and request, under certain circumstances as provided by the GDPR:</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-3 start" start={1}>
        <li className="c4 li-bullet-6">
          <span className="c2">
            to access your Personal Information together with information about how, and on what basis, such information
            is being processed. Should you desire to receive such information in a different format than the one that
            was provided to you, you can contact us via{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">
            &nbsp;and we shall use commercially reasonable efforts to accommodate your request, if applicable.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-9">
          <span className="c2">
            to rectify any of the Personal Information being held when such information is inaccurate.{' '}
          </span>
        </li>
        <li className="c4 li-bullet-6">
          <span className="c2">
            to delete or restrict access to your Personal Information in limited circumstances as described under the
            GDPR. Please note that if we need to delete any Personal Information related to you, as per your request, it
            can take time until we completely delete residual copies of such data from our servers and backup systems.
          </span>
        </li>
        <li className="c4 li-bullet-6">
          <span className="c2">
            to withdraw your consent to the processing of your Personal Information. However, please note that
            exercising this right will not affect the lawfulness of any previous processing activities based on consent
            that was lawfully obtained before its withdrawal.
          </span>
        </li>
        <li className="c4 li-bullet-6">
          <span className="c2">
            to obtain and reuse your Personal Information for your own purposes across different services, as part of
            your right to data portability.
          </span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1" start={3}>
        <li className="c9 li-bullet-5">
          <span className="c2">
            By accessing or using the Service or otherwise providing Information to us, you consent to the processing
            and transfer of information in and to the European Economic Area (EEA) and other countries and territories,
            which may have different privacy laws from your country of residence. In these instances, we will transfer
            your Personal Information only to such countries as approved by the European Commission as providing an
            adequate level of data protection or enter into legal agreements ensuring an adequate level of data
            protection.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Please note that you have the right to complain to a Data Protection Authority about our collection and use
            of your Personal Information. For more information, please contact your local data protection authority in
            the European Economic Area (EEA).
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            If you have any concerns with respect to our methods of processing any Personal Information related to you,
            or if wish to withdraw your consent, for any reason, kindly let us know by sending an email to{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">.</span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain shall not charge you for requesting to exercise any of the aforementioned rights.{' '}
          </span>
        </li>
      </ol>
      <p className="c23 c26">
        <span className="c2"></span>
      </p>
      <ol className="c15 lst-kix_list_4-0" start={6}>
        <li className="c12 li-bullet-0">
          <span className="c1">SECURITY</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            We take great care in implementing and maintaining the security of the Services and your Personal
            Information. We employ industry standard procedures to ensure the confidentiality, integrity, availability
            and safety of your Personal Information, and prevent unauthorized use of any such information. However given
            that no online service can be completely secured, we cannot guarantee that hackers or unauthorized personnel
            will not gain access to Personal Information despite our efforts.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            You should note that by using our Services, your Personal Information will be transferred through third
            party infrastructures which are not under our control. No method of transmission over the Internet, or
            method of electronic storage, is 100% secure. Therefore, while we strive to use commercially acceptable
            means to protect your Personal Information, we cannot guarantee its absolute security.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            You may access your account information and our Service only through the use of an individual username and
            password. To protect the confidentiality of Personal Information, you must keep your password confidential
            and not disclose it to any other person. Please advise us immediately if you believe your password has been
            misused. In addition, always logout and close your browser when you finish your session. Please note that we
            will never ask you to disclose your password in an unsolicited phone call or email.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            If you have any questions or concerns about the security of your Personal Information, you can contact us at{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">.</span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={7}>
        <li className="c12 li-bullet-0">
          <span className="c1">YOUR CHOICES</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c3">Account Information &amp; Retention</span>
          <span className="c2">
            . You may request us to update, correct, or delete (under certain circumstances) information about you at
            any time by emailing us at{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">.&nbsp;</span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            We will retain your Information for as long as it is necessary to fulfill our legitimate purposes as
            outlined in this Privacy Policy. We will retain and use your Information to comply with our legal
            obligations, internal record keeping, resolve disputes and enforce our agreements.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Upon request, Retrain will provide you with information about whether we hold, or process on behalf of a
            third party, any of your Personal Information that we are aware of. To request this information, contact us
            at{' '}
          </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">. </span>
        </li>
        <li className="c9 li-bullet-8">
          <span className="c2">When you send an email or other communication to </span>
          <span className="c5">
            <a className="c16" href="mailto:info@retrain.ai">
              privacy@retrain.ai
            </a>
          </span>
          <span className="c2">
            &nbsp;or any other correspondence that you have with us, we may retain those communications to process and
            respond to your requests and improve our Service.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-5">
          <span className="c2">Please </span>
          <span className="c24 c17">DO NOT</span>
          <span className="c2">
            &nbsp;send us any communication which contains confidential or sensitive information, since we are unable to
            evaluate whether your content constitutes as confidential or sensitive information, or not, and we may
            retain or use such communication as described hereinabove, and such retention or use shall not be deemed as
            a breach of any of our obligations pursuant to this Privacy Policy.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={8}>
        <li className="c12 li-bullet-3">
          <span className="c1">COMMUNITY FORUMS AND BLOGS</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Our Services may include publicly accessible blogs. You should be aware that any information you provide in
            these areas may be read, collected, and used by others who access them.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            To request removal of your Personal Information from our blog or testimonials, contact us at the email
            address listed above. In some cases, we may not be able to remove your Personal Information, in which case
            we will let you know if we are unable to do so and why.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={9}>
        <li className="c12 li-bullet-4">
          <span className="c1">TESTIMONIALS</span>
        </li>
      </ol>
      <p className="c36 c30">
        <span className="c2">
          Retrain display personal testimonials of satisfied customers on our website in addition to other endorsements.
          With your consent Retrain may post your testimonial along with your full name, company information, title, and
          photo/company logo.
        </span>
      </p>

      <ol className="c15 lst-kix_list_4-0" start={10}>
        <li className="c12 li-bullet-3">
          <span className="c1">LINKS TO THIRD PARTY WEBSITES</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-5">
          <span className="c2">
            Retrain may place links to other websites and resources provided by third parties via the Services,
            including links contained in advertisements, banner advertisements and sponsored links. Such links are
            provided for your convenience only.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            When you click on a link to a third-party website from our website, your activity and use on the linked
            website is governed by that website’s policies, not by those of Retrain.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            Retrain does not operate, control, or monitor these websites and their content and assumes no responsibility
            for such third-party websites, content, or any product and services offered by such third-party websites.
            Should you decide to access any of such third-party websites linked to or through the Service, you are doing
            so at your own risk and subject to the terms and conditions of use for such third-party websites.
            Accordingly, we encourage you to visit their websites and review their privacy and user policies.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={11}>
        <li className="c12 li-bullet-3">
          <span className="c1">OUR POLICY TOWARD CHILDREN</span>
        </li>
      </ol>
      <p className="c36 c30">
        <span className="c2">
          The Retrain Services is not directed to individuals under the age of consent (as determined under the
          applicable laws where the individual resides; “
        </span>
        <span className="c1">Age of Consent</span>
        <span className="c2">
          ”). We do not knowingly collect or solicit Personal Information from individuals under the Age of Consent. By
          accessing, using or interacting with our Services, you certify to us that you are not under the Age of
          Consent. If you become aware that a child has provided us with Personal Information, please contact us at 
        </span>
        <span className="c5">
          <a className="c16" href="mailto:info@retrain.ai">
            privacy@retrain.ai
          </a>
        </span>
        <span className="c2">
          .&nbsp;If we become aware that a child under the Age of Consent has provided us with Personal Information, we
          will take steps to delete such information as soon as applicable.
        </span>
      </p>

      <ol className="c15 lst-kix_list_4-0" start={12}>
        <li className="c12 li-bullet-3">
          <span className="c1">CHANGES TO THIS POLICY</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            We may change this Privacy Policy from time to time. If we make any changes, we will notify you by revising
            the "Last Updated" date at the bottom of this Privacy Policy and, in some cases, we may provide you with
            additional notice (such as adding a statement to our homepage or sending you an email notification). If
            there are material changes to this Privacy Policy. We encourage you to review our Privacy Policy whenever
            you access the Service to stay informed about our information practices and the ways you can help protect
            your privacy.
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            If you disagree with any changes to this Privacy Policy and do not wish your information to be subject to
            the revised Privacy Policy, you will need to deactivate and stop using the Service. Your use of any of the
            Service after the posting of such changes shall constitute your consent to such changes.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={13}>
        <li className="c12 li-bullet-3">
          <span className="c1">GOVERNING LAW AND DISPUTES</span>
        </li>
      </ol>
      <ol className="c15 lst-kix_list_4-1 start" start={1}>
        <li className="c9 li-bullet-1">
          <span className="c2">
            All disputes arising out of this Privacy Policy will be subject to the governing law of the State of
            Delaware, United States and the exclusive jurisdiction of the competent courts located in Delaware, United
            States.{' '}
          </span>
        </li>
        <li className="c9 li-bullet-1">
          <span className="c2">
            The parties agree and submit to the personal and exclusive jurisdiction and venue of these courts, except
            that nothing will prohibit either party from instituting an action in any court of competent jurisdiction to
            obtain injunctive relief or protect or enforce its intellectual property rights. The United Nations
            Convention on Contracts for the International Sale of Goods shall not apply to this Privacy Policy.
          </span>
        </li>
      </ol>

      <ol className="c15 lst-kix_list_4-0" start={14}>
        <li className="c12 li-bullet-3">
          <span className="c1">CONTACTING US</span>
        </li>
      </ol>
      <p className="c14 c30">
        <span className="c8">Any questions about this Privacy Policy should be addressed to </span>
        <span className="c10 c8">
          <a className="c16" href="mailto:info@retrain.ai">
            privacy@retrain.ai
          </a>
        </span>
        <span className="c2">. </span>
      </p>
    </div>
  );
};
