//TODO: check wht new text variants not working
//TODO: check problem when using sofi-client button
import { ColumnBox, FBox } from '@retrain-ai/ui-design';
import { useWindowSize } from 'react-use';
import { ReactComponent as BackgroundImage } from '../../images/background-mask.svg';
import signInImage from '../../images/sign-in.png';
import { useRtl } from '../../RTL';
import { useStyles } from './floating-card.style';
import { FloatingCardProps } from './types';
import { Grid, Link } from '@mui/material';

const minHeight = 700;

export const FloatingCard: React.FC<FloatingCardProps> = (props) => {
  const { children, bottomLinkText, bottomLinkHref, imageSrc } = props;
  const { height } = useWindowSize();
  const rtl = useRtl();

  const { classes: s, cx } = useStyles();

  return (
    <>
      <BackgroundImage className={s.background} />
      <ColumnBox
        className={s.screen}
        alignItems={'center'}
        // to make card scrollable for mobile album orientation
        height={height < minHeight ? 'auto' : '100%'}
      >
        <Grid container className={s.outerContainer} wrap="nowrap">
          <Grid item className={s.childrenContainer}>
            {children}
          </Grid>
          <Grid item className={cx(s.imageWrapper, rtl ? s.heImage : s.enImage)}>
            <img src={imageSrc || signInImage} alt="Sign In" className={s.image} />
          </Grid>
        </Grid>

        {bottomLinkText ? (
          <FBox alignItems="center" className={s.linkContainer}>
            <Link
              href={bottomLinkHref ? bottomLinkHref : '#'}
              className={s.link}
              target={bottomLinkHref ? '_blank' : '_self'}
            >
              {bottomLinkText}
            </Link>
          </FBox>
        ) : null}
      </ColumnBox>
    </>
  );
};
