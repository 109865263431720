import { Route, Switch } from 'react-router-dom';
import { SignIn } from './pages/sign-in/signIn';
import { ChooseTenant } from './pages/choose-tenant/choose-tenant';
import { navigateToFallbackUrl } from './pages/login/login.api';

export const AppWithRouterAccess = () => {
  return (
    <Switch>
      <Route path="/sign-in">
        <SignIn navigateToFallbackUrl={navigateToFallbackUrl} />
      </Route>
      <Route path="/">
        <ChooseTenant />
      </Route>
    </Switch>
  );
};
