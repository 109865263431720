import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    screen: {
      display: 'flex',
      justifyContent: 'center',
      margin: 'auto',

      [theme.breakpoints.down('xs')]: {
        paddingLeft: theme.spacing(5),
        paddingRight: theme.spacing(5),
      },

      [theme.breakpoints.up('sm')]: {
        paddingLeft: theme.spacing(15),
        paddingRight: theme.spacing(15),
      },

      [theme.breakpoints.up('lg')]: {
        paddingLeft: theme.spacing(30),
        paddingRight: theme.spacing(30),
      },
    },
    background: {
      position: 'absolute',
      zIndex: -1,
      height: '100%',
      width: '100%',
    },

    outerContainer: {
      background: theme.extraOptions.newPallette.White,
      boxShadow: '0px 0px 50px rgba(0, 0, 0, 0.1)',
      borderRadius: '40px',
      maxWidth: 1590,
      marginLeft: 50,
      marginRight: 50,
    },
    image: {
      display: 'block',
      height: '100%',
    },
    cardContent: {
      marginLeft: theme.spacing(3),
      height: '100%',
    },
    loginImage: {
      width: '100%',
      height: '100%',
    },
    linkContainer: {
      marginTop: theme.spacing(4),
    },
    link: {
      color: theme.extraOptions.newPallette.Purple,
      fontSize: 14,
      lineHeight: '18px',
    },

    imageWrapper: {
      width: '100%',
      overflow: 'hidden',

      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },

    enImage: {
      borderTopRightRadius: 40,
      borderBottomRightRadius: 40,
    },
    heImage: {
      borderTopLeftRadius: 40,
      borderBottomLeftRadius: 40,
    },

    childrenContainer: {
      display: 'flex',
      maxWidth: 551,
      width: '100%',
      height: '100%',
      overflow: 'auto',
      alignItems: 'center',
      margin: 'auto',

      [theme.breakpoints.up('sm')]: {
        maxHeight: 750,
      },
    },
  }),
);
