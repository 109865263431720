import { SignInOptionDtoMethodEnum } from '@retrain-ai/sign-in-manager-api';
import { ReactElement } from 'react';
import { ReactComponent as SSOIcon } from '../../images/sso.icon.svg';
import { ReactComponent as GoogleIcon } from '../../images/google.icon.svg';
import { ReactComponent as OktaIcon } from '../../images/okta-icon.svg';
import { authApi } from '../../pages/login/login.api';
import { Button as MButton } from '@mui/material';
import { Text, TextVariants } from '@retrain-ai/ui-design';
import { theme } from '../../theme';

export type LoginButtonProps = {
  method: SignInOptionDtoMethodEnum;
  id: string;
  className: string | undefined;
  idp?: string;
  text?: string;
};

const methodToIconMap: Record<SignInOptionDtoMethodEnum, ReactElement | null> = {
  Google: <GoogleIcon />,
  Linkedin: <GoogleIcon />,
  SSO: <SSOIcon />,
  Okta: <OktaIcon />,
  UserAndPassword: <div />,
  OTP: null,
};

export const LoginButton: React.FC<LoginButtonProps> = (props: LoginButtonProps) => {
  const { method, className, idp } = props;

  const handleLogin = async () => {
    const redirectUrl = new URL(window.location.href).searchParams.get('redirectUrl');

    const {
      data: { authorizationUrl },
    } = await authApi.authControllerSignIn({ idp: idp ?? '' }, { params: { redirectUrl } });
    window.location.href = authorizationUrl;
  };

  if (methodToIconMap[method] == null) {
    return null;
  }

  return (
    <MButton
      className={className}
      variant="outlined"
      startIcon={methodToIconMap[method]}
      onClick={() => handleLogin()}
      dir="ltr"
      style={{
        marginTop: theme.spacing(3),
      }}
      data-testid="login-button"
    >
      <Text variant={TextVariants.RunningText}>{props.text ? props.text : method.toString()}</Text>
    </MButton>
  );
};
