import { ReactNode, Ref } from 'react';

export interface OtpProps {
  logo: ReactNode;
  handleConfirm: () => void;
  handleBackButton: () => void;
}

export interface VerificationProps {
  verifyCode: () => void;
  invalidUserError: boolean;

  isDisabled?: boolean;
  onOtpChange: (value: string) => void;
  otpError: string;
  otp: string;
  otpRef: Ref<OtpRef>;
}
export interface OtpRef {
  focus: () => void;
}

export interface RegistrationProps {
  setError: (error: string) => void;
  isSubmitting: boolean;
  disabled: boolean;
}

export interface DestinationValuesProps {
  isPhone: boolean;
  phone: string;
  isEmail: boolean;
  email: string;
}

export enum Steps {
  destination = 'destination',
  verification = 'verification',
}
