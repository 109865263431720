import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()((theme) => ({
    dividerContainer: {
      position: 'relative',
      margin: theme.spacing(10.5, 0, 10.5),
    },

    dividerText: {
      backgroundColor: theme.extraOptions.newPallette.White,
      maxWidth: 156,
      width: '100%',
      margin: 'auto',
      textAlign: 'center',
      position: 'relative',
      marginTop: theme.spacing(-1.25),
    },

    divider: {
      backgroundColor: theme.extraOptions.newPallette.LightGrey,
    },
  }),
);
