import React from 'react';
import { useStyles } from './divider.style';
import { Divider as MDivider } from '@mui/material';

type Props = { containerClass?: string };

export const Divider: React.FC<Props> = ({ children, containerClass }) => {
  const { classes: s, cx } = useStyles();

  return (
    <div className={cx(s.dividerContainer, containerClass)}>
      <MDivider className={s.divider} />
      <div className={s.dividerText}>{children}</div>
    </div>
  );
};
