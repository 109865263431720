import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ColumnBox, Text, TextVariants, Button, Checkbox, BoxDialog } from '@retrain-ai/ui-design';
import { useStyles } from './compliance.style';
import { FloatingCard } from '../../components/floating-card/floating-card';
import { TermsOfUse } from '../../components/terms-of-use/terms-of-use';
import { PrivacyPolicy } from '../../components/privacy-policy/privacy-policy';
import { FooterText } from '../../components/footer-text/footer-text';

export type RoleType = 'Candidate' | 'Recruiter';

type Props = { onConfirm: () => void; tenantUrl: string };

type DialogContentType = 'terms' | 'privacy';

const tenantNameMap: Record<string, string> = {
  'skeelz-candidate.retrain.ai': '11111',
  unknown: '00000',
};

const getTenantName = (key: string) => tenantNameMap[key] ?? tenantNameMap.unknown;

export const Compliance: React.FC<Props> = ({ onConfirm, tenantUrl }) => {
  const { classes: s } = useStyles();
  const { t } = useTranslation();
  const [terms, setTerms] = useState<boolean>(false);
  const [information, setInformation] = useState<boolean>(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [dialogContent, setDialogContent] = useState<DialogContentType>('terms');

  const chooseDialogContent = (contentId: DialogContentType) => {
    setDialogContent(contentId);
    setDialogOpen(true);
  };

  const dialogTitle = dialogContent === 'terms' ? 'legal.terms.title' : 'legal.privacy.title';
  const dialogContentComponent = dialogContent === 'terms' ? <TermsOfUse /> : <PrivacyPolicy />;

  return (
    <FloatingCard bottomLinkText={<FooterText />} bottomLinkHref={'https://www.retrain.ai'}>
      <ColumnBox className={s.container}>
        <Text variant={TextVariants.RunningText}>{t('common.verification-successful')}</Text>

        <Text variant={TextVariants.PageTitle} className={s.titleText}>
          <div>{t('common.you-may-log-in')}</div>
        </Text>

        <div className={s.termsCheckboxWrapper}>
          <Checkbox
            checked={terms}
            onChange={(e) => setTerms(e.target.checked)}
            variant="square24"
            label={
              <Text bold={true} variant={TextVariants.RunningText}>
                {t('legal.i-agree-to')}
                <span className={s.termsOfUseLink} onClick={() => chooseDialogContent('terms')}>
                  {t('legal.terms-of-use')}
                </span>
                {t('legal.and')}
                <span className={s.termsOfUseLink} onClick={() => chooseDialogContent('privacy')}>
                  {t('legal.privacy-policy')}
                </span>
              </Text>
            }
          />
        </div>

        <Checkbox
          checked={information}
          onChange={(e) => setInformation(e.target.checked)}
          variant="square24"
          label={
            <Text bold={true} variant={TextVariants.RunningText}>
              {' '}
              {t('legal.information', {
                companyName: getTenantName(tenantUrl),
              })}
            </Text>
          }
        />

        <Button onClick={onConfirm} className={s.footerButton} disabled={!terms || !information} size="large">
          <Text variant={TextVariants.SubtitleSemiBold}>{t('legal.continue')}</Text>
        </Button>

        <BoxDialog
          isOpen={isDialogOpen}
          onCloseClicked={() => setDialogOpen(false)}
          title={{ label: dialogTitle }}
          classes={{ dialog: s.termsDialogContent }}
          dialogContent={<ColumnBox>{dialogContentComponent}</ColumnBox>}
        />
      </ColumnBox>
    </FloatingCard>
  );
};
