import { useEffect, useRef } from 'react';
import { Callback, IOptions, IState } from './types';

const DEFAULT_TIMEOUT = 60 * 1000;

function isSupported() {
  return 'OTPCredential' in window && typeof AbortController !== 'undefined';
}

async function readSMS(controller: AbortController) {
  const content: any = await navigator.credentials.get({
    signal: controller.signal,
    otp: { transport: ['sms'] },
  } as any);

  if (!content || !content.code) {
    throw new Error('Unable to read otp');
  }
  return content.code;
}

export function useReadOtp(callback: Callback, options: IOptions = {}) {
  const state = useRef<IState>({});

  function abort() {
    state.current.abortController?.abort();

    if (state.current.timer) {
      clearTimeout(state.current.timer);
    }
  }

  useEffect(() => {
    if (!isSupported()) {
      return;
    }

    if (!(options.isEnabled ?? true)) {
      abort();
      return;
    }

    const controller = (state.current.abortController = new AbortController());

    readSMS(controller)
      .then((otp) => callback(otp))
      .catch((err) => options.onError && options.onError(err))
      .finally(() => abort());

    state.current.timer = setTimeout(abort, options.timeout ?? DEFAULT_TIMEOUT);

    return abort;
  }, [callback, options, options.isEnabled]);

  return abort;
}
