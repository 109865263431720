import * as yup from 'yup';
import { validate } from '../../../constants/validation';
import { DestinationValuesProps } from './types';

export const channels = {
  sms: 'sms',
  call: 'call',
  email: 'email',
};

export const initialDestination: DestinationValuesProps = {
  email: '',
  phone: '',
  isPhone: true,
  isEmail: false,
};

export const validationSchema = (t: Function) =>
  yup.object({
    email: yup.string().when(['isEmail'], {
      is: (isPhone: Boolean) => isPhone,
      then: validate(t).email,
    }),
    phone: yup.string().when(['isPhone'], {
      is: (isEmail: Boolean) => isEmail,
      then: validate(t).phone,
    }),
  });
