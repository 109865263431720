import { makeStyles } from 'tss-react/mui';

export const useStyles = makeStyles()(() => ({
    root: {
      overflow: 'hidden auto',
      width: '100%',
      height: '100%',
      position: 'relative',
      background: 'linear-gradient(180deg, #F9FBFF 100%, #FFFFFF 100%);',
      textAlign: 'center',
    },
    wrapper: {
      width: '360px',
      padding: '42px 20px',
      position: 'relative',
    },
    image: {
      width: '148px',
      height: '198px',
      marginBottom: '38px',
    },
    firstParagraph: {
      marginBottom: '20px',
    },
    closeIcon: {
      cursor: 'pointer',
      position: 'absolute',
      top: -25,

      '& svg': {
        fill: '#262626',
        height: 19.5,
        width: 19.5,
      },
    },
    closeIconRight: {
      right: 25,
    },
    closeIconLeft: {
      left: 25,
    },
  }),
);
